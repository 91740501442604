import React, { useContext } from 'react';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ScrollContainer from 'react-indiana-drag-scroll';
import { pdfjs } from 'react-pdf';
import './PDFView.scss';
import DownloadFile from '../DownloadFile/DownloadFile';
import UserContext from '../UserContext/UserContext';

const Document = React.lazy(() => import('react-pdf/dist/esm/entry.webpack').then((module) => ({ default: module.Document })));
const Page = React.lazy(() => import('react-pdf/dist/esm/entry.webpack').then((module) => ({ default: module.Page })));

// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
export default function PDFView({ doc, docName, docType, zoom, onDocumentLoadSuccess, pageNumber, handleZoom, changePage, numPages, pageWidth }) {
  const { userPermissions } = useContext(UserContext);

  return (
    <>
      <ScrollContainer
        style={{ overflowX: zoom > 1 ? 'auto' : 'hidden', display: 'flex', justifyContent: zoom > 1 ? '' : 'center' }}
        className="document cardStyle"
        hideScrollbars={false}
        vertical={true}
        horizontal={true}
      >
        <div
          style={{
            transform: zoom > 1 ? '' : `scale(${zoom})`,
            transformOrigin: zoom > 1 ? '' : 'center center',
            display: 'flex',
            justifyContent: zoom > 1 ? '' : 'center',
            margin: zoom > 1.1 ? '2rem' : '',
          }}
        >
          <React.Suspense fallback={<div>Loading PDF...</div>}>
            <Document
              // options={{
              //   cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
              //   cMapPacked: true,
              // }}
              file={`data:application/pdf;base64,${doc}`}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) => console.log('Failed to load pdf', error)}
            >
              <Page pageNumber={pageNumber} scale={zoom} width={pageWidth * zoom} />
            </Document>
          </React.Suspense>
        </div>
      </ScrollContainer>

      {doc && (
        <div className="w-100">
          <div className="changePage" style={{ display: 'flex', alignItems: zoom > 1 ? '' : 'center' }}>
            <IconButton disabled={zoom === 1.0} onClick={() => handleZoom('out')}>
              <ZoomOutIcon
                style={{
                  fontSize: 40,
                }}
              />
            </IconButton>
            <IconButton disabled={pageNumber === 1} onClick={() => changePage('left')}>
              <ChevronLeftIcon
                style={{
                  fontSize: 40,
                }}
              />
            </IconButton>
            <div className="pagesLabel">
              Page {pageNumber} of {numPages}
            </div>
            <IconButton disabled={pageNumber === numPages} onClick={() => changePage('right')}>
              <ChevronRightIcon
                style={{
                  fontSize: 40,
                }}
              />
            </IconButton>
            <IconButton disabled={zoom > 1.3} onClick={() => handleZoom('in')}>
              <ZoomInIcon
                style={{
                  fontSize: 40,
                }}
              />
            </IconButton>

            {userPermissions['Download_File'] === true && (
              <>
                {' '}
                <DownloadFile doc={doc} docName={docName} docType={docType}></DownloadFile>{' '}
                <div className="ml-2" style={{ color: 'black', fontWeight: 'bold' }}>
                  PDF
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
