import { Route, Redirect } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { GlobalLoader } from './newcomponents/GlobalLoader/GlobalLoader';
import FetchLocalStorageDataByPath from './utils/helper/FetchLocalstorageDataByPath.js';
import UserContext from './components/UserContext/UserContext.js';
import { fixedPermissions } from './utils/helper/permissionFinder.js';

const AdminRoute = ({ component: Component, ...rest }) => {
  const [isFeatureActive, setisFeatureActive] = useState(null);
  const [loading, setLoading] = useState(true);
  let isSuperUser = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.superUser;
  const { userPermissions, setUserPermissions } = useContext(UserContext);
  const findPermissions = async () => {
    let x = JSON.parse(sessionStorage.getItem('user_information')) !== undefined ? JSON.parse(sessionStorage.getItem('user_information'))?.rolePermissions : [];
    let obj = {};
    if (x !== undefined && x.length > 0) {
      await x.map((i) => {
        if (fixedPermissions.includes(i) === true) {
          obj[i] = true;
        }
        return null;
      });

      setUserPermissions(obj);
    }
  };

  useEffect(() => {
    findPermissions();
  }, []);
  const { path } = rest;
  const newPath = path.substr(1);

  useEffect(() => {
    getLocalStorageDataFunc();
  }, [path]);

  const userRole =
    // JSON.parse(sessionStorage.getItem('user_information'))?.rolesName.includes('Admin') ||
    // JSON.parse(sessionStorage.getItem('user_information'))?.rolesName.includes('Application Administrator') ||
    // JSON.parse(sessionStorage.getItem('user_information'))?.rolesName.includes('Redaction Admin') ||
    userPermissions['Administrator_Panel'] === true;

  const getLocalStorageDataFunc = async () => {
    try {
      const fetchedLocalStorageData = FetchLocalStorageDataByPath('system_config', 'features') ?? [];
      const crudsPermissions = FetchLocalStorageDataByPath('system_config', 'adminPanel');
      const crudsPermissionsFound = await crudsPermissions.find((values) => values.path_name === newPath);
      const foundFeature = await fetchedLocalStorageData.find((values) => values.path_name === newPath);

      if (foundFeature) {
        setisFeatureActive((prev) => ({
          ...prev,
          [newPath]: foundFeature.value,
        }));
      } else if (crudsPermissionsFound) {
        setisFeatureActive((prev) => ({
          ...prev,
          [newPath]: crudsPermissionsFound.value,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        // if (userRole !== true) {
        //   return <Redirect to={'/login'} />;
        // }

        if (loading) {
          return <GlobalLoader />;
        }

        if (isFeatureActive === null || !isFeatureActive.hasOwnProperty(newPath)) {
          return <Component {...props} />;
        }

        if (isFeatureActive['systemconfiguration'] === true && !isSuperUser) {
          return <Redirect to={'/inbasketpage'} />;
        }

        if (isFeatureActive[newPath] === true || (isSuperUser === true && newPath === 'admin')) {
          return <Component {...props} />;
        }

        return <Redirect to={'/inbasketpage'} />;
      }}
    />
  );
};

export default AdminRoute;
