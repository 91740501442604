import axios from 'axios';

export async function createBatchXwalk(data) {
  let updateResponse = [];
  try {
    const url = '/buow_api/batchxwalk';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in creating routing orchestration: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function updateOneBatchXwalk(data) {
  let updateResponse = [];
  try {
    const url =
      `${process.env.REACT_APP_BACKEND_URL}/buow_api/batchxwalk/` +
      `?batchId=${data.batchId}` +
      `&buowId=${data.buowId}` +
      `&contextId=${data.contextId}` +
      `&entitySelectionId=${data.prevEntitySelectionId}`;

    delete data.prevEntitySelectionId;

    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in deleting routing orchestration data: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deleteBatchXwalk(params) {
  let deleteResponse = {};
  try {
    const url =
      `/buow_api/batchxwalk/` + `?batchId=${params.batchId}` + `&buowId=${params.buowId}` + `&contextId=${params.contextId}` + `&entitySelectionId=${params.entitySelectionId}`;
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error in deleting routing orchestration data: ', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function searchBatchXwalk(data) {
  let workQueueFilterResponse = [];
  try {
    let url = `${process.env.REACT_APP_BACKEND_URL}/buow_api/batchxwalk/search?contextId=${data.contextId}&keywords=${data.text}`;

    if (data.column !== '' && data.order !== '') {
      url += `&sortOn=${data.column}&sortOrder=${data.order}`;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getUserInfoID(text) {
  let workQueueFilterResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/smc_security_api/user/' + text;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function searchBatchId(data) {
  let workQueueFilterResponse = [];
  try {
    let url = process.env.REACT_APP_BACKEND_URL + `/buow_api/batchdefinition/${data}`;

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
