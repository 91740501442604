import { Field, FieldWrapper, Form } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import HoldTypeDropDownList from "../../HoldTypeDropDownList";
import FormDatepicker from "../../../../components/FormModules/FormDatepicker";

export default function UpdateHoldForm(props) {
  const { onSubmit, dataTestId } = props;
  const initialValues = {
    holdstatus: "Active"
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmitClick={onSubmit}
      render={(formRenderProps) => {
        return (
          <div data-testid={dataTestId}>
            <div className={'row mt-3'}>
              <FieldWrapper className={'col-12'}>
                <label className="fsa-field__label">Hold Name</label>
                <Field name={'holdName'} className="fsa-input fsa-field__item" component={Input}/>
              </FieldWrapper>
            </div>

            <div className={'row mt-3'}>
              <FieldWrapper className={'col-12'}>
                <label className="fsa-field__label">Type of Hold</label>
                <Field name={'holdtype'} 
                component={HoldTypeDropDownList}
                />
              </FieldWrapper>
            </div>

            <div className={'row mt-3'}>
              <FieldWrapper className={'col-12'}>
                <label className="fsa-field__label">USDA Officer E-mail</label>
                <Field
                  name={'responsibleOfficerEmail'}
                  className="fsa-input fsa-field__item"
                  component={Input}
                />
              </FieldWrapper>
            </div>

           

            <div className={'row mt-3'}>
              <FieldWrapper className={'col-12'}>
                <label className="fsa-field__label">Participants/Litigants</label>
                <Field name={'participants'} className="fsa-input fsa-field__item" component={Input}/>
              </FieldWrapper>
            </div>

            <div className={'row mt-3'}>
              <FieldWrapper className={'col-12'}>
                <label className="fsa-field__label">Hold Effective Date</label>
                <Field name={'effectiveDate'} className=" fsa-field__item" component={FormDatepicker}/>
              </FieldWrapper>
            </div>

            <div className={'row mt-3'}>
              <FieldWrapper className={'col-12'}>
                <label className="fsa-field__label">Hold Scope</label>
                <Field name={'holdScope'} className="fsa-input fsa-field__item" component={Input}/>
              </FieldWrapper>
            </div>

            <div className={'row mt-3'}>
              <FieldWrapper className={'col-12'}>
                <label className="fsa-field__label">Hold Number</label>
                <Field name={'holdNumber'} className="fsa-input fsa-field__item" component={Input}/>
              </FieldWrapper>
            </div>

            <div className={'row mt-4'}>
              <div style={{display: 'flex'}} className={'col-12 justify-content-end'}>
                <button onClick={formRenderProps.onSubmit} className="fsa-btn">Search</button>
                <button onClick={formRenderProps.onFormReset} className="fsa-btn fsa-btn--secondary">Clear</button>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}