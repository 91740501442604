import React from "react";
import mimeTypes from "../mimeType/mimeType";
import DownloadIcon from '../../fsa-style/img/svgs/downloadIcon.svg'
import './DownloadFile.scss';
export default function DownloadFile({ doc, docName, docType }) {
  const getMimeType = name => {
    const nameSplit = name.split(".");
    const type = nameSplit[nameSplit.length - 1];
    return mimeTypes[type];
  };

  return (
    <>
      <a
        download={docName}
        href={
          docType === "pdf"
            ? `data:application/pdf;base64,${doc}`
            : `data:${getMimeType(docName)};base64,${doc}`
        }
        className="downloadIconStyle"
      >
        <img src={DownloadIcon} alt="Download icon" className="downloadIconStyle"/>
      </a>
    </>
  );
}
