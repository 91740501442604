import axios from 'axios';

export async function createSearchPage(data) {
  let createResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/systemconfiguration';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        createResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.response.data);
    createResponse.error = ex.response.data;
  }
  // console.log(createResponse);
  return createResponse;
}

export async function updateSearchData(data) {
  let updateResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/systemconfiguration/' + data.id;
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deleteSearchData(data) {
  let deleteResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + `/buow_api/systemconfiguration/${data.id}`;
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function onSearchFileData(data) {
  let workQueueFilterResponse = [];
  try {
    let url = process.env.REACT_APP_BACKEND_URL + '/buow_api/systemconfiguration/';
    if (data.text !== '' && data.column === '' && data.order === '') {
      url += 'search?keywords=' + data.text;
    } else {
      url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

// export async function sortSearchData(data) {
//   let workQueueFilterResponse = [];
//   try {
//     const url = '/buow_api/systemconfiguration/sort/' + data.column + '/' + data.order;
//     await axios
//       .get(url, {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       })
//       .then((response) => {
//         workQueueFilterResponse = response;
//       });
//   } catch (ex) {
//     console.log('Error in get messages ', ex.message);
//     workQueueFilterResponse.error = ex.response.data;
//   }
//   return workQueueFilterResponse;
// }
export async function searchPageColumnsAPI() {
  let workQueueFilterResponse = [];
  let defualt_folder_level = JSON.parse(sessionStorage.getItem('user_information'))?.userPreference?.defaultSearchGroupLevel;
  try {
    let url = process.env.REACT_APP_BACKEND_URL + '/buow_api/systemfolderoptions?level=' + defualt_folder_level;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function fetchAdditionalMetadata(buowId) {
  let workQueueFilterResponse = [];
  try {
    let url = process.env.REACT_APP_BACKEND_URL + `/buow_api/batchxwalk/buow/${buowId}`;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
