import axios from 'axios';

export async function searchUnitOfWork(data) {
  let workQueueFilterResponse = [];
  try {
    let url = process.env.REACT_APP_BACKEND_URL + '/buow_api/unitofwork/';
    if (data.text !== '' && data.column === '' && data.order === '') {
      url += 'search?keywords=' + data.text;
    } else {
      url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function createUnitOfWork(data) {
  let updateResponse = [];
  try {
    const url = '/buow_api/unitofwork';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in creating Unit of work: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function updateUnitOfWork(data) {
  let updateResponse = [];

  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/unitofwork/' + `?id=${data.id}`;
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          //   Authorization: "Bearer " + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deleteUnitOfWork(data) {
  let deleteResponse = [];
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/buow_api/unitofwork/${data.id}`;
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          //   Authorization: "Bearer " + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function getBuowName(text) {
  let workQueueFilterResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/buowdisplayname/search/' + text;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getObjectType(data) {
  console.log('getObjectType', data);
  let workQueueFilterResponse = [];
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/buow_api/objecttype/search/${data.searchKeyword}?contextId=${data.contextId}`;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getObjectTypeList(data) {
  let workQueueFilterResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/objecttype/?contextId=' + data?.contextId;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getDefaultBuowName() {
  let workQueueFilterResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/buowdisplayname/';
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
