import React, { useEffect, useState, useContext } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import styles from './SingleObjectViewDetails.module.scss';
import FullscreenIcon from '../../fsa-style/img/svgs/fullScreenIcon.svg';
import crossIcon from '../../fsa-style/img/crossIcon.svg';
import { useWindowSize } from '../../services/helper';
import { getDocDetail, objectSpecificList, getCustomers } from '../../services/metadataSubmissionService';
import { CircularProgress } from '@material-ui/core';
import PDFView from '../../components/PDFView/PDFView';
import ImageView from '../../components/ImageView/ImageView';
import UserContext from '../../components/UserContext/UserContext';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { v4 as uuidv4 } from 'uuid';
import { GlobalLoader } from '../GlobalLoader/GlobalLoader';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { groupData, sectionData } from '../../utils/helper/helperData';
import { handleCustomErrorMsg } from '../../utils/helper/handleReuiredErrorFunc';
import EditIcon from '../../fsa-style/img/svgs/EditPencilBlue.svg';
import FormDatepicker from '../../components/FormModules/FormDatepicker';
import moment from 'moment';
import { EditMetadata, updateDispositionDate, getFileCodeById } from '../../services/searchService';
import _ from 'lodash';
import SuccessProcessGrowl from '../../components/SuspenseQueue/SuccessProcessGrowl';
import ErrorGrowl from '../../components/SuspenseQueue/ErrorGrowl';
import { getDoctype } from '../../services/suspenseQueueService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronUp, faChevronDown, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import UpdateObjectFileCodeModal from '../../components/modals/UpdateObjectFileCodeModal/UpdateObjectFileCodeModal';
import UpdateObjectHoldModal from '../../components/modals/UpdateObjectHoldModal/UpdateObjectHoldModal';
import AliceSenseTab from '../../pages/Search/AliceSenseTab/AliceSenseTab';
import { ListView } from '@progress/kendo-react-listview';
import { searchHolds } from '../../services/recordsManagementService';

export const SingleObjectViewDetailsModal = ({ setOpenObjectPopup, openObjectPopup, recordId, setRecordId, getData }) => {
  const { userPermissions } = useContext(UserContext);
  const [expand, setExpand] = useState('DEFAULT');
  const [selected, setSelected] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [width, height] = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [objectDetailsResponse, setObjectDetailsResponse] = useState(null);
  const [objectMetadata, setObjectMetadata] = useState();

  const [docType, setDocType] = useState();
  const [docName, setDocName] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [documentType, setDocumentType] = useState([]);
  const [errorMessage, setSearchErrorMessage] = useState('No records were found matching your search criteria.');
  const [doc, setDoc] = useState();
  const [loaderUpdateMetaData, setLoaderUpdateMetaData] = useState(false);
  const [sectionList, setSectionList] = useState(_.cloneDeep(sectionData));
  const [groupList, setGroupList] = useState(_.cloneDeep(groupData));
  const [groupSelection, setGroupSelection] = useState(null);
  const [sectionSelection, setSectionSelection] = useState(null);
  // const [sectionFilteredData, setSectionFilteredData] = useState(sectionList.slice());
  // const [groupFilteredData, setGroupFilteredData] = useState(groupList.slice());
  const [editedFields, setEditedFields] = useState();
  const [doctypeFilteredData, setDoctypeFilteredData] = useState(documentType.slice());
  const [documentTitleSelection, setDocumentTitleSelection] = useState(null);
  const [editDocumentTitle, setEditDocumentTitle] = useState();
  const [folderEditable, setFolderEditable] = useState(false);
  const [EditRefresh, setEditRefresh] = useState(false);

  const [folderValue, setFolderValue] = useState('');
  const [isRequiredError, setisRequiredError] = useState(false);
  const [metadataDisplayList, setMetadataDisplayList] = useState(null);
  const [detailsObjList, setDetailsObjList] = useState([]);
  const [objectTypeToView, selectObjectTypeToView] = useState(null);
  const [isMetadataModified, setIsMetadataModified] = useState(false);
  // const [userId, setUserId] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  // const [isSuccessGrowlHidden1, setIsSuccessGrowlHidden1] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [message, setMessage] = useState('');
  const [agencyCode, setAgencyCode] = useState();
  const [modalTitle, setModalTitle] = useState();
  const [loadingFileCodes, setLoadingFileCodes] = useState(false);
  const [isHoldModalVisible, setIsHoldModalVisible] = useState(false);
  const [objFileCodePopupOpen, setObjFileCodePopupOpen] = useState(false);
  const [sectionOpen, setSectionOpen] = useState(false);
  const [fileCodeName, setFileCodeName] = useState(null);
  const [records, setRecords] = useState({ metadata: [] });
  const [startDate, setStartDate] = useState(null);
  const [holdInfo, setHoldInfo] = useState(null);
  const [holdResponse, setHoldResponse] = useState([]);
  // const [scimsCustomers, setScimsCustomers] = useState(null);

  const fetchSingleObjectDetails = (e) => {
    setLoading(true);
    getDocDetail(e)
      .then((response) => {
        setObjectDetailsResponse(response.data);
        setObjectMetadata(response.data.metadata);
        selectObjectTypeToView(response.data.metadata._id);
        // setUserId(response.data.metadata._id);
        setRecordId(response.data.metadata._id);
        setAgencyCode(response.data.metadata.mda_source.agency_code);
        //   setSections(response.data.metadata.object_specific.sections);
        setDoc(response.data.documentData.file);
        //   setDocumentTitle(response.data.metadata.object_type.object_type_name);
        getFileType(response.data.documentData.fileName);

        setRecords({
          metadata: [response.data.metadata],
        });
        objectSpecificList(response.data.metadata.object_type.object_type_name).then((schemaResponse) => {
          const schema = schemaResponse.data[0].objecttypeMetadata[0];
          const required = JSON.parse(schema.metadataRequired);
          Object.keys(required).map((key) => (required[key].searchable === 'false' ? delete required[key] : {}));
          const optional = JSON.parse(schema.metadataOptional);
          Object.keys(optional).map((key) => (optional[key].searchable === 'false' ? delete optional[key] : {}));

          const combined = Object.assign({}, required, optional);
          const flat = Object.flatten(response?.data?.metadata?.object_specific);

          Object.keys(flat).map((key) => Object.keys(combined).map((keyMaster) => (keyMaster === key ? (combined[keyMaster].value = flat[key]) : null)));
          const selectedObjTYpe = detailsObjList.find((element) => element._id === objectTypeToView);

          //   populateScimsCustomerData(selectedObjTYpe);
          setMetadataDisplayList(combined);

          setLoading(false);
        });
      })
      .catch((err) => {
        setLoading(false);
        setSearchErrorMessage('No records were found matching your search criteria.');
      });
  };

  useEffect(() => {
    fetchSingleObjectDetails(recordId);
    searchHolds({ holdStatus: '' }).then((res) => {
      setHoldResponse(res?.data);
    });
    returnTitle();
  }, []);

  const expandHandler = () => {
    if (expand === 'DEFAULT') {
      setExpand('FULLSCREEN');
    } else {
      setExpand('DEFAULT');
    }
  };

  const fetchDocDetails = (selectedObj, oldObj) => {
    getDocDetail(selectedObj)
      .then((response) => {
        let findEntry = detailsObjList.map((itm) => {
          if (itm._id === oldObj) {
            return response.data.metadata;
          } else return itm;
        });

        setDetailsObjList(findEntry);
        selectObjectTypeToView(response.data.metadata._id);
        setRecordId(response.data.metadata._id);
        setObjectDetailsResponse(response.data);
        setObjectMetadata(response.data.metadata);
        // setUserId(response.data.metadata._id);
        setRecordId(response.data.metadata._id);
        setAgencyCode(response.data.metadata.mda_source.agency_code);
        // setSections(response.data.metadata.object_specific.sections);
        setDoc(response.data.documentData.file);
        // setDocumentTitle(response.data.metadata.object_type.object_type_name);
        getFileType(response.data.documentData.fileName);
        setRecords({
          metadata: [response.data.metadata],
        });
        objectSpecificList(response.data.metadata.object_type.object_type_name).then((schemaResponse) => {
          // const schema = schemaResponse.data[0].objecttypeMetadata.find((res) => res.metadataStartdate === response.data.metadata.object_type.edition_date);
          const schema = schemaResponse.data[0].objecttypeMetadata[0];
          const required = JSON.parse(schema?.metadataRequired);
          Object.keys(required).map((key) => (required[key].searchable === 'false' ? delete required[key] : {}));
          const optional = JSON.parse(schema.metadataOptional);
          Object.keys(optional).map((key) => (optional[key].searchable === 'false' ? delete optional[key] : {}));

          const combined = Object.assign({}, required, optional);
          const flat = Object.flatten(response.data.metadata.object_specific);

          Object.keys(flat).map((key) => Object.keys(combined).map((keyMaster) => (keyMaster === key ? (combined[keyMaster].value = flat[key]) : null)));
          // populateScimsCustomerData(_.find(result.data.metadata, { _id: objectTypeToView }));
          setMetadataDisplayList(combined);
        });
      })
      .catch((err) => {
        setLoading(false);
        setSearchErrorMessage('No records were found matching your search criteria.');
      });
  };

  const closePopup = () => {
    setOpenObjectPopup(false);
    setPageNumber(1);
    setObjectMetadata(null);

    getData();
  };

  const closePopupActions = () => {
    closePopup();
    setExpand('DEFAULT');
    setSelected(0);
    setZoom(1.0);
    if (isMetadataModified === true) {
      //   refreshData();
      setIsMetadataModified(false);
    }
  };

  const getFileType = (filePath) => {
    const returnedDocName = filePath;
    setDocName(returnedDocName);
    const docNameSplit = returnedDocName.split('.');
    const returnedDocType = returnedDocName.split('.')[docNameSplit.length - 1];
    if (returnedDocType === 'pdf') {
      setDocType(returnedDocType);
    } else if (returnedDocType === 'bmp' || returnedDocType === 'jpg' || returnedDocType === 'jpeg' || returnedDocType === 'img') {
      setDocType('image');
    } else {
      setDocType('');
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (direction) => {
    direction === 'left' ? setPageNumber((prevPageNumber) => prevPageNumber - 1) : setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handleZoom = (zoomDirection) => {
    zoomDirection === 'in' ? setZoom((previousZoom) => previousZoom + 0.1) : setZoom((previousZoom) => previousZoom - 0.1);
  };
  const returnTitle = () => {
    let systemFolders = JSON.parse(sessionStorage.getItem('SystemColumns'));

    let title = '';
    let arrow = '';
    if (objectMetadata !== undefined) {
      if (systemFolders.length > 0) {
        systemFolders.map((itm, i) => {
          if (objectMetadata?.object_specific[itm?.name.replace(/\s/g, '')] !== undefined) {
            if (title.length > 0) {
              arrow = ' > ';
            } else arrow = '';
            title = title + arrow + objectMetadata?.object_specific[itm?.name.replace(/\s/g, '')];
          }
        });

        setModalTitle(title);
      }
    }
  };

  useEffect(() => {
    const getdoc = async () => {
      try {
        const response = await getDoctype();
        if (Array.isArray(response.data)) {
          const mappedData = response.data.map((item, index) => ({
            id: index + 1,
            display_name: item.objectTypeName,
          }));
          setDocumentType(mappedData);
          setDoctypeFilteredData(mappedData);
        } else {
          console.error('Invalid data format from API:', response.data);
        }
      } catch (error) {
        console.error('Error in getDoctype:', error.message);
      }
    };

    getdoc();
    setDocumentTitleSelection(_.find(documentType, { display_name: objectDetailsResponse?.metadata.object_type?.title }));
    setEditDocumentTitle((values) => ({
      ...values,
      object_type_name: objectDetailsResponse?.metadata.object_type?.object_type_name,
      title: objectDetailsResponse?.metadata.object_type?.title,
    }));
    setSectionSelection(_.find(sectionList, { display_name: objectDetailsResponse?.metadata.object_specific?.Section }));
    setGroupSelection(_.find(groupList, { display_name: objectDetailsResponse?.metadata.object_specific?.Group }));
    setFolderValue(objectDetailsResponse?.metadata.object_specific?.Folder);
    setEditedFields((values) => ({
      ...values,
      sections: objectDetailsResponse?.metadata.object_specific?.Section,
      title: objectDetailsResponse?.metadata.object_type?.title,
      Group: objectDetailsResponse?.metadata?.Group,
      Folder: objectDetailsResponse?.metadata?.Folder,
    }));
    returnTitle();
  }, [objectDetailsResponse, modalTitle]);

  // selects the tabstrip
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  // for section dropdown
  const sectionDropListChange = (e) => {
    setSectionSelection(e.target.value);
    let key = 'sections';
    let value = e.target.value.display_name;
    setEditedFields((values) => ({ ...values, [key]: value }));
  };

  // for group dropdown
  const groupDropdownChange = (e) => {
    setGroupSelection(e.target.value);
    let key = 'Group';
    let value = e.target.value.display_name;
    setEditedFields((values) => ({ ...values, [key]: value }));
  };

  // for object type dropdown
  const DocTitleDropListChange = (e) => {
    setDocumentTitleSelection(e.target.value);
    let keyTitle = 'title';
    let keyObjectType = 'object_type_name';
    let value = e.target.value.display_name;
    setEditDocumentTitle((values) => ({
      ...values,
      [keyTitle]: value,
      [keyObjectType]: value,
    }));
  };
  const isCustomerInfo = (key) => {
    return (
      key.startsWith('producer[') ||
      key.startsWith('producer.') ||
      key.startsWith('entity.') ||
      key.startsWith('entity[') ||
      key.startsWith('participant.') ||
      key.startsWith('participant[') ||
      key.startsWith('member_info.') ||
      key.startsWith('member_info[') ||
      key.startsWith('guardian.') ||
      key.startsWith('guardian[') ||
      key.startsWith('title') ||
      key.startsWith('object_type_name') ||
      key.startsWith('edition_date')
    );
  };

  // Edit Text Code Section Start
  const EditDisplayList = (ele, idx, checkEdit) => {
    // if (checkEdit === 'metadataDisplayList') {
    //   Object.keys(objectMetadata).forEach((key, index) => {
    //     setEditRefresh(!EditRefresh);
    //     if (key === 'object_type') {
    //       return (objectMetadata[key].isEditSelected = false); // if metadataDisplayList is selected then objectMetadata is input false
    //     }
    //   });
    //   Object.keys(ele).forEach((key, index) => {
    //     setEditRefresh(!EditRefresh);
    //     if (idx === index) {
    //       return (ele[key].isEditSelected = !ele[key].isEditSelected);
    //     } else {
    //       return (ele[key].isEditSelected = false);
    //     }
    //   });
    // }
    // if (checkEdit === 'objectMetadata') {
    //   Object.keys(ele).forEach((key, index) => {
    //     setEditRefresh(!EditRefresh);
    //     return (ele[key].isEditSelected = false); // if objectMetadata is selected then metadataDisplayList is input false
    //   });
    //   Object.keys(objectMetadata).forEach((key, index) => {
    //     setEditRefresh(!EditRefresh);
    //     if (key === 'object_type') {
    //       return (objectMetadata[key].isEditSelected = !objectMetadata[key].isEditSelected);
    //     }
    //   });
    // }
    const listToUpdate = checkEdit === 'metadataDisplayList' ? metadataDisplayList : objectMetadata;

    Object.keys(ele).forEach((key, index) => {
      setEditRefresh(!EditRefresh);
      return (listToUpdate[key].isEditSelected = idx === index ? !listToUpdate[key].isEditSelected : false);
    });

    Object.keys(objectMetadata).forEach((key, index) => {
      setEditRefresh(!EditRefresh);
      if (key === 'object_type') {
        return (objectMetadata[key].isEditSelected = !objectMetadata[key].isEditSelected);
      }
    });
  };

  Object.flatten = function (data) {
    var result = {};
    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + '[' + i + ']');
        if (l == 0) result[prop] = [];
      } else {
        var isEmpty = true;
        for (var p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + '.' + p : p);
        }
        if (isEmpty && prop) result[prop] = {};
      }
    }
    recurse(data, '');
    return result;
  };

  const holdArrFilter = async () => {
    let filteredHold =
      holdResponse?.length !== 0 &&
      objectMetadata?.holds &&
      holdResponse?.find((holdItm) => holdItm?.holdNumber === objectMetadata?.holds[objectMetadata?.holds.length - 1]?.hold_number);
    setHoldInfo(filteredHold);
    let fileCodeid = objectMetadata?.record_management && objectMetadata?.record_management?.file_code;
    if (fileCodeid) {
      const response = await getFileCodeById(fileCodeid, {});
      if (response) setFileCodeName(response.recordtitle);
    } else setFileCodeName('');
  };
  useEffect(() => {
    holdArrFilter();
    setStartDate([]);
    setPageNumber(1);
    if (objectMetadata && objectMetadata.record_management) {
      if (objectMetadata?.record_management?.projected_cut_off_date) setStartDate(moment(objectMetadata.record_management.projected_cut_off_date).format('YYYY-MM-DD'));
    }
  }, [objectMetadata, objFileCodePopupOpen]);

  useEffect(() => {
    if (isHoldModalVisible === false) {
      fetchSingleObjectDetails(recordId);
    }
  }, [isHoldModalVisible]);

  // save details function

  const onSaveButtonClick = async () => {
    let systemFolders = JSON.parse(sessionStorage.getItem('SystemColumns'));
    if (folderValue === '') return;
    if (groupSelection === '' || groupSelection === null || groupSelection === undefined) return;

    Object.keys(metadataDisplayList).forEach((key) => {
      return (metadataDisplayList[key].isEditSelected = false);
    });

    try {
      const headers = {
        agencycode: agencyCode,
        applicationid: 0,
      };

      setObjectMetadata((prevEditedFields) => ({
        // ...prevEditedFields,
        ...(prevEditedFields['object_specific'] = {
          ...prevEditedFields.object_specific,
          ...editedFields,
        }),
        ...(prevEditedFields['object_type'] = {
          ...prevEditedFields.object_type,
          ...editDocumentTitle,
        }),
      }));
      if (objectMetadata?.object_specific?.first_name === '' || objectMetadata?.object_specific?.last_name === '' || objectMetadata?.object_specific?.employee_number === '') {
        setisRequiredError(true);

        return;
      }
      setLoaderUpdateMetaData(true);
      objectMetadata['object_specific']['Section'] = objectMetadata['object_specific']['sections'];
      objectMetadata['object_specific']['ObjectType'] = objectMetadata['object_type']['title'];
      objectMetadata['object_specific']['Folder'] = folderValue;

      delete objectMetadata[systemFolders[0].name];
      // delete objectMetadata.Folder;
      delete objectMetadata.ObjectType;
      delete objectMetadata.Section;
      delete objectMetadata.contextID;
      delete objectMetadata.document_ocr_id;
      delete objectMetadata.emp_status;
      delete objectMetadata.fsa_access_level;
      delete objectMetadata.isEditSelected;
      delete objectMetadata.object_ocr;
      delete objectMetadata.object_type_name;
      delete objectMetadata.smc_department;
      // delete objectMetadata.isEditSelected;
      // delete objectMetadata.isEditSelected;

      delete objectMetadata.contract_number;
      delete objectMetadata.employee_number;
      delete objectMetadata.first_name;
      delete objectMetadata.last_name;
      delete objectMetadata.middle_name;
      delete objectMetadata.sections;
      delete objectMetadata.termination_date;
      delete objectMetadata.term_date;
      delete objectMetadata.title;
      if (objectMetadata?.object_specific?.term_date) {
        objectMetadata.object_specific.term_date = moment(objectMetadata.object_specific.term_date).format('YYYY-MM-DD');
      }
      // delete objectMetadata.object_specific.term_date;
      // delete objectMetadata.object_specific.emp_status;
      const response = await EditMetadata(recordId, objectMetadata, headers);

      if (response) {
        setIsSuccessGrowlHidden(false);
        setMessage('Successfully updated file object metadata.');
        fetchDocDetails(response?._id, recordId);
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
          setMessage('');
        }, 3000);

        setObjectMetadata((prevState) => ({
          ...prevState,
          ...response,
        }));
        setIsMetadataModified(true);
        setLoaderUpdateMetaData(false);
      } else {
        setTimeout(() => {
          setLoaderUpdateMetaData(false);
        }, [4000]);

        console.log('error in EditMetadata()');
      }
    } catch (error) {
      // Handle error
      setLoaderUpdateMetaData(false);

      console.error('Error in onSaveButtonClick:', error);
    }
  };

  const onChangeInput = (e, idx, checkEdit, currentField, metadataDisplayList) => {
    if (checkEdit === 'metadataDisplayList') {
      let value = e.target.value;
      if (currentField === 'Name Last' || currentField === 'Name First' || currentField === 'Employee Number') {
        value = e.target.value.trim() === '' ? '' : e.target.value;
        setisRequiredError(true);
      }
      Object.keys(metadataDisplayList).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        if (idx === index && currentField) {
          setEditedFields((prevEditedFields) => ({
            ...prevEditedFields,
            [key]: (metadataDisplayList[key].value = value),
          }));
        }
      });
    }
    if (checkEdit === 'objectMetadata') {
      let value = e.target.value;
      Object.keys(objectMetadata).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        if (key === 'object_type') {
          return (objectMetadata[key].title = value);
        }
      });
    }
  };

  // for saving input details
  const onSaveDisplayList = (e, idx, checkEdit, metadataList) => {
    // if (checkEdit === 'metadataDisplayList') {
    //   if (e.key === 'Enter') {
    //     let value = e.target.value;
    //     Object.keys(metadataDisplayList).forEach((key, index) => {

    //       setEditRefresh(!EditRefresh);
    //       if (idx === index) {

    //         return (metadataDisplayList[key].isEditSelected = false), (metadataDisplayList[key].value = value);
    //       }
    //     });
    //   }
    // }
    // if (list['first_name'].value === '' || list['last_name'].value === '' || list['employee_number'].value === '') {
    //   setisRequiredError(true);
    // }
    // if (checkEdit === 'objectMetadata') {
    //   if (e.key === 'Enter') {
    //     let value = e.target.value;
    //     Object.keys(objectMetadata).forEach((key, index) => {
    //       setEditRefresh(!EditRefresh);
    //       if (key === 'object_type') {
    //         return (objectMetadata[key].isEditSelected = false), (objectMetadata[key].title = value);
    //       }
    //     });
    //   }
    // }
    if (e.key === 'Enter') {
      const listToUpdate = checkEdit === 'metadataDisplayList' ? metadataList : objectMetadata;
      Object.keys(listToUpdate).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        if (idx === index) {
          listToUpdate[key].isEditSelected = false;
          listToUpdate[key].value = e.target.value;
        }
      });
      if (['first_name', 'last_name', 'employee_number'].some((field) => !metadataDisplayList[field]?.value)) {
        setisRequiredError(true);
      }
    }
  };

  const metadataView = (metadataDisplayList) => {
    metadataDisplayList = Object.fromEntries(
      Object.entries(metadataDisplayList).sort((x, y) => {
        if (x[1].display_name < y[1].display_name) return -1;
        if (x[1].display_name > y[1].display_name) return 1;
        return 0;
      })
    );
    if (metadataDisplayList)
      return Object.keys(metadataDisplayList).map((key, idx) => {
        if (key === 'sections') return null;

        if (key === 'object_ocr') return null;

        if (key === 'full_text_search') return null;

        if (key === 'document_ocr_id') return null;

        if (!isCustomerInfo(key)) {
          return (
            <>
              <div className={styles.returnedData} key={idx + '_metadata'}>
                <div className={styles.keyfont}>{metadataDisplayList[key]?.display_name}</div>
                <div className={styles.valuefont}>
                  {metadataDisplayList[key].isEditSelected === true ? (
                    <>
                      {metadataDisplayList[key].display_name === 'Term Date' && (
                        <>
                          <FormDatepicker
                            value={metadataDisplayList[key].value}
                            onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[key].display_name, metadataDisplayList)}
                          />
                        </>
                      )}
                      {metadataDisplayList[key].display_name === 'Employment Status' && (
                        <>
                          <select
                            value={metadataDisplayList[key].value === true || metadataDisplayList[key].value === 'true' || metadataDisplayList[key].value === 'True' ? true : false}
                            onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[key].display_name, metadataDisplayList)}
                          >
                            <option value={'true'}>Active</option>

                            <option value={'false'}>In-Active</option>
                          </select>
                        </>
                      )}

                      {metadataDisplayList[key].display_name !== 'Term Date' && metadataDisplayList[key].display_name !== 'Employment Status' && (
                        <>
                          <input
                            value={metadataDisplayList[key].value !== '.' && metadataDisplayList[key].value}
                            onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[key].display_name, metadataDisplayList)}
                            onKeyDown={(e) => onSaveDisplayList(e, idx, 'metadataDisplayList', metadataDisplayList)}
                            style={{ width: 'calc(100% - 15%)' }}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {metadataDisplayList[key].display_name === 'Term Date' && metadataDisplayList[key].value && (
                        <>{moment(metadataDisplayList[key].value).format('MM/DD/YYYY')}</>
                      )}

                      {metadataDisplayList[key].display_name === 'Employment Status' &&
                        (metadataDisplayList[key].value === 'Active' ||
                        metadataDisplayList[key].value === 'TERMINATED' ||
                        metadataDisplayList[key].value === 'In-Active' ||
                        metadataDisplayList[key].value === 'true' ||
                        metadataDisplayList[key].value === 'True' ||
                        metadataDisplayList[key].value === 'False' ||
                        metadataDisplayList[key].value === true ||
                        metadataDisplayList[key].value === false ||
                        metadataDisplayList[key].value === 'false' ? (
                          <>
                            {metadataDisplayList[key].value === 'Active' && 'Active'}
                            {metadataDisplayList[key].value === 'TERMINATED' && 'In-Active'}
                            {metadataDisplayList[key].value === 'In-Active' && 'In-Active'}
                            {metadataDisplayList[key].value === 'true' && 'Active'}
                            {metadataDisplayList[key].value === 'True' && 'Active'}
                            {metadataDisplayList[key].value === 'false' && 'In-Active'}
                            {metadataDisplayList[key].value === true && 'Active'}
                            {metadataDisplayList[key].value === false && 'In-Active'}
                            {metadataDisplayList[key].value === 'False' && 'In-Active'}
                          </>
                        ) : (
                          <>{metadataDisplayList[key].value && metadataDisplayList[key].value}</>
                        ))}
                      {metadataDisplayList[key].display_name !== 'Term Date' && metadataDisplayList[key].display_name !== 'Employment Status' && metadataDisplayList[key].value}
                    </>
                  )}
                  <img
                    style={{
                      marginLeft: '2px',
                      padding: '2px 0px 4px 10px',
                      height: '2.5rem',
                      cursor: 'pointer',
                    }}
                    alt="pencilIcon"
                    src={EditIcon}
                    onClick={() => EditDisplayList(metadataDisplayList, idx, 'metadataDisplayList')}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end">
                {metadataDisplayList[key].display_name === 'Name First' &&
                  metadataDisplayList['first_name']?.value === '' &&
                  isRequiredError === true &&
                  handleCustomErrorMsg('This field is required.')}
                {metadataDisplayList[key].display_name === 'Name Last' &&
                  metadataDisplayList['last_name']?.value === '' &&
                  isRequiredError === true &&
                  handleCustomErrorMsg('This field is required.')}
                {metadataDisplayList[key].display_name === 'Employee Number' &&
                  metadataDisplayList['employee_number']?.value === '' &&
                  isRequiredError === true &&
                  handleCustomErrorMsg('This field is required.')}
              </div>
            </>
          );
        }
      });
  };

  const toggleUpdateObjFileCodePopup = (popupState) => {
    setLoadingFileCodes(true);
    setObjFileCodePopupOpen(popupState);
    if (popupState === false) updateObjSelected(objectTypeToView);
  };
  const closeHoldModal = () => {
    setIsHoldModalVisible(false);
    updateObjSelected(objectTypeToView);
  };

  const openHoldModal = () => {
    setIsHoldModalVisible(true);
  };
  const closeSection = () => {
    if (!sectionOpen) {
      setSectionOpen(true);
    } else {
      setSectionOpen(false);
    }
  };

  // Disposition Information Functionality Start
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const UpdateDisposition = async () => {
    setLoaderUpdateMetaData(true);
    let payload = [];
    payload.push(recordId);
    try {
      const headers = {
        agencycode: agencyCode,
        applicationid: 0,
      };
      let newDate = startDate;
      if (startDate !== '') newDate = startDate + 'T00:00:00';
      else newDate = null;
      const response = await updateDispositionDate(newDate, payload, headers);

      if (response) {
        setLoaderUpdateMetaData(false);
        updateObjSelected(objectTypeToView);
        setMessage('Disposition data populated successfully');
        setIsSuccessGrowlHidden(false);
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
          setMessage('');
        }, 3000);
      }
    } catch (error) {
      // Handle error
      setLoaderUpdateMetaData(false);
      setMessage(error.response?.data?.message ? error.response.data.message : 'Something went wrong');
      console.error('Error in onSaveButtonClick:', error);
      setIsErrorGrowlHidden(false);
      setTimeout(() => {
        setIsErrorGrowlHidden(true);
        setMessage('');
      }, 3000);
    }
  };
  // Disposition Information Functionality End

  //TODO Move this out to component
  const LogItemRender = (props) => {
    let item = props.dataItem;
    return (
      <div className={`p-2 border-bottom align-middle ${styles.voteDetailRow} ${styles.center}`}>
        <div className={`col-12 ${styles.voteDetailCol}`}>
          <h2 className={`text-uppercase ${styles.voteDetail}`}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span
                style={{
                  display: 'inline-block',
                  width: '36%',
                  textAlign: 'left',
                }}
              >
                {item.change_log.split('_').join(' ')}
              </span>
              <span
                style={{
                  verticalAlign: 'middle',
                  display: 'inline-block',
                  width: '30%',
                  textAlign: 'right',
                }}
              >
                {item.modification_date.split('T').join(' ')}
              </span>
            </div>
          </h2>
        </div>
      </div>
    );
  };

  const FcLogItemRender = (props, data) => {
    let item = props.dataItem;
    return (
      <div className={`p-2 border-bottom align-middle ${styles.voteDetailRow} ${styles.center}`}>
        <div className={`col-12 ${styles.voteDetailCol}`}>
          <h2 className={`text-uppercase ${styles.voteDetail}`}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  display: 'inline-block',
                  width: '50%',
                  textAlign: 'left',
                }}
              >
                {data.event_name}
              </span>
              <span
                style={{
                  verticalAlign: 'top',
                  display: 'inline-block',
                  width: '30%',
                  textAlign: 'right',
                }}
              >
                {item.application_date.split('T').join(' ')}
              </span>

              {/* <span
                  style={{
                    verticalAlign: 'top',
                    display: 'inline-block',
                    width: '33%',
                    textAlign: 'right',
                  }}
                >
                  {item.user_name}
                </span> */}
            </div>
          </h2>
        </div>
      </div>
    );
  };

  const addCustomerNode = (data, node, ccids) => {
    if (data.object_specific[node]) {
      if (Array.isArray(data.object_specific[node])) {
        data.object_specific[node].map((producer) => {
          if (producer.core_customer_id) {
            ccids.push(producer.core_customer_id);
          }
        });
      } else {
        if (data.object_specific[node].core_customer_id) {
          ccids.push(data.object_specific[node].core_customer_id);
        }
      }
    }

    return ccids;
  };
  const populateScimsCustomerData = (data) => {
    let ccids = [];
    if (data) {
      ccids = addCustomerNode(data, 'producer', ccids);
      ccids = addCustomerNode(data, 'entity', ccids);
      ccids = addCustomerNode(data, 'member_info', ccids);
      ccids = addCustomerNode(data, 'participant', ccids);
      ccids = addCustomerNode(data, 'guardian', ccids);

      // if (ccids.length > 0) {
      //   getCustomers(ccids).then((response) => {
      //     setScimsCustomers(response.data);
      //   });
      // }
    }
  };
  const updateObjSelected = (e) => {
    // setIsLoading(true);
    setLoading(true);
    selectObjectTypeToView(e);

    // let paramsArray =null;
    // const metadataSearchObject = CreateSearchObjectSearch(paramsArray ? paramsArray : searchParamsObjArray, sort, doctypeSelection, stateSelection, countySelection);

    // fetchSearchDetailsObjectsData(metadataSearchObject)
    getDocDetail(e).then((response) => {
      setObjectDetailsResponse(response.data);
      setObjectMetadata(response.data.metadata);
      setRecordId(response.data.metadata._id);
      setAgencyCode(response.data.metadata.mda_source.agency_code);
      // setSections(response.data.metadata.object_specific.sections);
      setDoc(response.data.documentData.file);
      // setDocumentTitle(response.data.metadata.object_type.object_type_name);
      getFileType(response.data.documentData.fileName);
      objectSpecificList(response.data.metadata.object_type.object_type_name).then((schemaResponse) => {
        const schema = schemaResponse.data[0].objecttypeMetadata[0];
        const required = JSON.parse(schema.metadataRequired);
        Object.keys(required).map((key) => (required[key].searchable === 'false' ? delete required[key] : {}));
        const optional = JSON.parse(schema.metadataOptional);
        Object.keys(optional).map((key) => (optional[key].searchable === 'false' ? delete optional[key] : {}));

        const combined = Object.assign({}, required, optional);
        const flat = Object.flatten(response.data.metadata.object_specific);

        Object.keys(flat).map((key) => Object.keys(combined).map((keyMaster) => (keyMaster === key ? (combined[keyMaster].value = flat[key]) : null)));
        const selectedObjTYpe = detailsObjList.find((element) => element._id === objectTypeToView);

        populateScimsCustomerData(selectedObjTYpe);
        setMetadataDisplayList(combined);
        setLoading(false);
      });
    });
  };
  return (
    <div>
      <Window
        style={{
          paddingLeft: '10px',
          paddingRight: '10px',
          maxHeight: '100%',
        }}
        minimizeButton={() => null}
        maximizeButton={() => null}
        stage={expand}
        title={
          <span className={styles.modalHeaderTitleStyle} title={modalTitle}>
            {modalTitle}
          </span>
        }
        closeButton={() => (
          <>
            <button aria-label="Expand Detail View Window" style={{ backgroundColor: 'Transparent', border: 'none' }} onClick={() => expandHandler()}>
              {/* <b>X</b> */}
              <img src={FullscreenIcon} alt="FullScreen icon" />
            </button>
            <button aria-label="Close Detail View Window" style={{ backgroundColor: 'Transparent', border: 'none' }} onClick={() => closePopupActions()}>
              {/* <b>X</b> */}
              <img src={crossIcon} alt="cross icon" />
            </button>
          </>
        )}
        restoreButton={() => null}
        modal={true}
        draggable={false}
        left={width * 0.025}
        top={height * 0.05}
        onClose={closePopupActions}
        height={height * 0.9}
        width={width * 0.95}
      >
        {loading ? (
          <div className={styles.center}>
            <CircularProgress />
          </div>
        ) : objectMetadata ? (
          <>
            <div className={styles.detailViewCon}>
              <div className={styles.fileViewer}>
                {docType === 'pdf' && doc !== undefined && (
                  <>
                    {' '}
                    <header className={styles.objectTypeHeaderContainer}>
                      <p className={styles.objectTypeHeading}>Preview</p>
                    </header>
                    <PDFView
                      doc={doc}
                      docName={docName}
                      docType={docType}
                      onDocumentLoadSuccess={onDocumentLoadSuccess}
                      pageNumber={pageNumber}
                      changePage={changePage}
                      numPages={numPages}
                      zoom={zoom}
                      handleZoom={handleZoom}
                      pageWidth={900}
                    ></PDFView>
                  </>
                )}
                {docType === 'image' && <ImageView doc={doc} docName={docName}></ImageView>}
              </div>
              <TabStrip className={styles.detailViewer} selected={selected} onSelect={handleSelect}>
                {userPermissions['ObjectMetadata_View_Object'] === true && (
                  <TabStripTab title="Object Metadata">
                    {/* TODO1  */}
                    <div
                      className={styles.metadata}
                      style={{
                        pointerEvents: loaderUpdateMetaData === true ? 'none' : 'auto',
                      }}
                    >
                      <div className={styles.returnedData} key={uuidv4()}>
                        {/* start Group dropdown */}
                        <div className={styles.keyfont}>Group</div>
                        <div className="fsa-grid__1/2">
                          <div className={`fsa-field ${styles.dropdownCustomStyle}`}>
                            <DropDownList
                              data={groupList}
                              textField="display_name"
                              dataItemKey="id"
                              value={groupSelection}
                              onChange={groupDropdownChange}
                              style={{ width: 'calc(100%)' }}
                            />
                          </div>
                        </div>
                        {/* end Group dropdown */}
                      </div>
                      {(groupSelection === '' || groupSelection === null || groupSelection === undefined) && (
                        <div className="d-flex justify-content-end">{handleCustomErrorMsg('This field is required.')}</div>
                      )}
                      <div className={styles.returnedData} key={uuidv4()}>
                        {/* start Object Type dropdown */}
                        <div className={styles.keyfont}>Object Type</div>
                        <div className="fsa-grid__1/2">
                          <div className={`fsa-field ${styles.dropdownCustomStyle}`}>
                            <DropDownList
                              data={doctypeFilteredData}
                              textField="display_name"
                              dataItemKey="id"
                              value={documentTitleSelection}
                              onChange={DocTitleDropListChange}
                              style={{ width: 'calc(100%)' }}
                            />
                          </div>
                        </div>
                        {/* end Object Type dropdown */}
                      </div>

                      <div className={styles.returnedData} key={uuidv4()}>
                        {/* start Sections dropdown */}
                        <div className={styles.keyfont}>Section</div>
                        <div className="fsa-grid__1/2">
                          <div className={`fsa-field ${styles.dropdownCustomStyle}`}>
                            <DropDownList
                              data={sectionList}
                              textField="display_name"
                              dataItemKey="id"
                              value={sectionSelection}
                              onChange={sectionDropListChange}
                              style={{ width: 'calc(100%)' }}
                            />
                          </div>
                        </div>
                        {/* end Sections dropdown */}
                      </div>
                      <div className={styles.returnedData}>
                        <div className={styles.keyfont}>Folder</div>
                        <div className={`fsa-field ${styles.valuefont} mb-0`}>
                          {folderEditable ? (
                            <input
                              onChange={(e) => {
                                e.preventDefault();
                                setEditRefresh(!EditRefresh);
                                setFolderValue(e.target.value);
                              }}
                              onKeyPress={(e) => {
                                e.key === 'Enter' && setFolderEditable(!folderEditable);
                              }}
                              style={{ width: 'calc(100% - 15%)' }}
                              value={folderValue}
                            />
                          ) : (
                            folderValue
                          )}
                          <img
                            style={{
                              marginLeft: '2px',
                              padding: '2px 0px 4px 10px',
                              height: '2.5rem',
                              cursor: 'pointer',
                            }}
                            src={EditIcon}
                            alt="pencilIcon"
                            onClick={() => setFolderEditable(!folderEditable)}
                          />
                        </div>
                      </div>

                      {folderValue === '' && <div className="d-flex justify-content-end">{handleCustomErrorMsg('This field is required.')}</div>}

                      {metadataView(metadataDisplayList)}
                    </div>
                    <div
                      style={{
                        textAlign: 'right',
                        marginTop: '10px',
                        marginRight: '1em',
                      }}
                      className="d-flex justify-content-end"
                    >
                      <button onClick={onSaveButtonClick} disabled={loaderUpdateMetaData} className="fsa-btn">
                        <span>Save</span>
                      </button>
                      {loaderUpdateMetaData && (
                        <div>
                          <GlobalLoader />
                        </div>
                      )}
                    </div>
                  </TabStripTab>
                )}

                {userPermissions['RecordManagement_View_Object'] === true && (
                  <TabStripTab title="Records Management">
                    {/* TODO2 RENAME to AssignFileCode */}
                    {/* <SearchDetail metaData={objectMetadata} /> */}

                    <div>
                      <div className={styles.detailSection}>
                        <div className={styles.detailSectionTitle}>
                          <h4>Filecode information</h4>
                          <div className="flexrow">
                            <button onClick={() => toggleUpdateObjFileCodePopup(true)} className="fsa-btn clear-btn">
                              Update Filecode
                            </button>
                            <span>
                              {sectionOpen ? (
                                <FontAwesomeIcon onClick={closeSection} className="float-right chevron" icon={faChevronUp} />
                              ) : (
                                <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                              )}
                            </span>
                          </div>
                        </div>
                        <div className={styles.detailSectionContent}>
                          <div className={styles.metaItem}>
                            <span className={styles.metaName}>File Code Name: {fileCodeName}</span>
                          </div>
                          <div className={styles.metaItem}>
                            <span className={styles.metaName}>File Code id: {objectMetadata.record_management ? objectMetadata.record_management.file_code : ''}</span>
                          </div>
                        </div>
                      </div>
                      <UpdateObjectFileCodeModal
                        setObjectMetadata={setObjectMetadata}
                        objectMetadata={objectMetadata}
                        objectID={recordId}
                        windowHeight={window.innerHeight}
                        windowWidth={window.innerWidth}
                        loadingFileCodes={loadingFileCodes}
                        setLoadingFileCodes={setLoadingFileCodes}
                        objFileCodePopupOpen={objFileCodePopupOpen}
                        setRecords={setRecords}
                        records={records}
                        toggleUpdateObjFileCodePopup={toggleUpdateObjFileCodePopup}
                        ids={[objectMetadata._id]}
                      ></UpdateObjectFileCodeModal>

                      <div className={styles.detailSection}>
                        <div className={styles.detailSectionTitle}>
                          <h4>Hold information</h4>
                          <div className="flexrow">
                            <button className="fsa-btn clear-btn" onClick={openHoldModal}>
                              Update Hold
                            </button>
                            <span>
                              {sectionOpen ? (
                                <FontAwesomeIcon onClick={closeSection} className="float-right chevron" icon={faChevronUp} />
                              ) : (
                                <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                              )}
                            </span>
                          </div>
                        </div>
                        <div className={styles.detailSectionContent}>
                          <div className={styles.metaItem}>
                            <span className={styles.metaName}>Hold Name: {holdInfo !== null ? holdInfo?.holdName : ''}</span>
                          </div>
                          <div className={styles.metaItem}>
                            <span className={styles.metaName}>Type of Hold: {holdInfo !== null ? holdInfo?.holdtype : ''}</span>
                            <br></br>
                          </div>
                        </div>
                      </div>
                      <UpdateObjectHoldModal onClose={closeHoldModal} visible={isHoldModalVisible} recordIds={[objectMetadata?._id]} />

                      {/* Disposition Information Start */}
                      <div className={styles.detailSection}>
                        <div className={styles.detailSectionTitle}>
                          <h4>Disposition information</h4>
                          <div className="flexrow">
                            <button className="fsa-btn clear-btn" onClick={UpdateDisposition}>
                              Update Disposition
                            </button>
                            <span>
                              {sectionOpen ? (
                                <FontAwesomeIcon onClick={closeSection} className="float-right chevron" icon={faChevronUp} />
                              ) : (
                                <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                              )}
                            </span>
                          </div>
                        </div>
                        <div className={styles.detailSectionContent}>
                          <div className={styles.metaItem}>
                            <span className={styles.metaName}>Cutoff Date:</span>
                            {/* <DatePicker className="disposition-datepicker" onChange={handleStartDateChange} selected={startDate} /> */}

                            <input type="date" value={startDate} format="MM/dd/yyyy" name={'end_' + startDate} onChange={handleStartDateChange} />
                          </div>
                          <div className={styles.metaItem}>
                            <span className={styles.metaName}>Disposition Date:</span>
                            <span>
                              {objectMetadata?.record_management?.projected_disposition_date !== undefined
                                ? moment(objectMetadata?.record_management?.projected_disposition_date).format('MM-DD-YYYY')
                                : '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Disposition Information End */}
                      <div
                        style={{
                          textAlign: 'right',
                          marginTop: '10px',
                          marginRight: '1em',
                        }}
                        className="d-flex justify-content-end"
                      >
                        {' '}
                        <button onClick={onSaveButtonClick} disabled={loaderUpdateMetaData} className="fsa-btn">
                          <span>Save</span>
                        </button>
                        {loaderUpdateMetaData && (
                          <div>
                            <GlobalLoader />
                          </div>
                        )}
                      </div>
                    </div>
                  </TabStripTab>
                )}
                {userPermissions['View_Logs'] === true && (
                  <TabStripTab title="Logs">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <a
                        style={{
                          color: '#fff',
                          borderRadius: '5px',
                          padding: '2rem 0 2rem',
                          height: '100%',
                          width: '100%',
                          textAlign: 'center',
                          marginBottom: '1em',
                          background: '#205493',
                          textDecoration: 'none',
                          fontSize: '18px',
                          fontWeight: '500',
                          letterSpacing: '0.24px',
                          lineHeight: 'normal',
                        }}
                        download={'logs.txt'}
                        href={`data:text/plain,${JSON.stringify(objectMetadata.doc_modification_history)}`}
                      >
                        Download Logs
                      </a>
                    </div>

                    <h3 style={{ color: '#000' }}>Object History</h3>
                    <ListView style={{ maxHeight: '45 em', overflow: 'hidden' }} data={objectMetadata.doc_modification_history} item={LogItemRender} className="k-listview" />
                    <h3 style={{ color: '#000' }}>Records Management History</h3>
                    <ListView
                      style={{ maxHeight: '45em', overflow: 'hidden' }}
                      data={objectMetadata.record_management ? objectMetadata.record_management.file_code_change_history : []}
                      item={(props) => FcLogItemRender(props, objectMetadata.record_management)}
                      className="k-listview"
                    />
                  </TabStripTab>
                )}
                {userPermissions['Can_View_Sense'] === true && (
                  <TabStripTab title="SENSE - β">
                    <AliceSenseTab docNumber={objectMetadata?.object_specific?.document_ocr_id} />
                  </TabStripTab>
                )}
              </TabStrip>
            </div>
          </>
        ) : (
          <div className={styles.center}>
            <h3>{errorMessage}</h3>
            <div className="fsa-divider"></div>
          </div>
        )}
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
        {/* <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden1} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden1} message={message} /> */}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
      </Window>
    </div>
  );
};
