import axios from 'axios';

export async function getAllRuleTrigger() {
  let workQueueFilterResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/ruletrigger';
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          //   Authorization: "Bearer " + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getRuleTriggerById(data) {
  let workQueueFilterResponse = [];
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/buow_api/ruletrigger/${data.id}`;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          //   Authorization: "Bearer " + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function createRuleTrigger(data) {
  let createResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/ruletrigger';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          //   Authorization: "Bearer " + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        createResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.response.data);
    createResponse.error = ex.response.data;
  }
  // console.log(createResponse);
  return createResponse;
}

export async function updateRuleTrigger(data) {
  let updateResponse = [];

  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/ruletrigger/' + data.id;
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          //   Authorization: "Bearer " + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deleteRuleTrigger(data) {
  let deleteResponse = [];
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/buow_api/ruletrigger/${data.id}`;
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          //   Authorization: "Bearer " + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function searchRuleTriggerTable(data) {
  let workQueueFilterResponse = [];
  try {
    let url = process.env.REACT_APP_BACKEND_URL + '/buow_api/ruletrigger/';
    if (data.text !== '' && data.column === '' && data.order === '') {
      url += 'search?keywords=' + data.text;
    } else {
      url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          //   Authorization: "Bearer " + data.token,
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getBuowName(text) {
  let workQueueFilterResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/buowdisplayname/search/' + text;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getDefaultBuowName() {
  let workQueueFilterResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/buowdisplayname/';
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getRuleIdBySearch(text) {
  let workQueueFilterResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/ruledefinition/search/' + text;

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getDefaultRule() {
  let workQueueFilterResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/ruledefinition/';
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getDefaultElement(data) {
  let workQueueFilterResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + `/buow_api/objectdefinition/elementname?contextId=${data.contextId}&keywords=${data.text}`;

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
