import axios from 'axios';

export async function createRoutingConditions(data) {
  let updateResponse = [];
  try {
    const url = '/buow_api/routingconditions';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in creating Routing Conditions: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function updateOneRoutingConditions(data) {
  let updateResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + `/buow_api/routingconditions?contextId=${data.contextId}&conditionId=${data.conditionId}`;
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in deleting Routing Conditions data: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deleteRoutingConditions(params) {
  let deleteResponse = {};
  try {
    const url = `/buow_api/routingconditions?contextId=${params.contextId}&conditionId=${params.conditionId}`;
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error in deleting Routing Conditions data: ', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function searchRoutingConditions(data) {
  let workQueueFilterResponse = [];
  try {
    let url = process.env.REACT_APP_BACKEND_URL + '/buow_api/routingconditions/';

    if (data.text !== '' && data.column === '' && data.order === '') {
      url += 'search?keywords=' + data.text + '&contextId=' + data.contextId;
    } else {
      url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order + '&contextId=' + data.contextId;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
