import React, { useEffect, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './RedactionDefinition.module.scss';
import '../../../GlobalStyle.scss';

import RedactionDefinitionData from './RedactionDefinitionData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import {
  createRedactionDefinition,
  updateOneRedactionDefinition,
  deleteRedactionDefinition,
  searchRedactionDefinition,
  getObjectIds,
  searchDefaultRedactionReason,
  getElementIdList,
  getDefaultElement,
} from './RedactionDefinitionService';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';
import { getSearchContextId } from '../RoutingSteps/RoutingStepsServices';

const TableRedactionDefinition = lazy(() => import('./TableRedactionDefinition'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Redaction Definition', link: '' },
];

const validateVisualTagData = ['Yes', 'No'];
const redactData = ['Yes', 'No', 'Conditional'];
const redactionTypeData = ['Default', 'String'];
const conditionalRuleIdData = ['Yes', 'No', 'Conditional'];

export default function RedactionDefinition() {
  const glbContextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  // const [selectedRows, setSelectedRows] = useState();
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [redactionDefinitionDetails, setRedactionDefinitionData] = useState(RedactionDefinitionData);
  const [inputDisabled, setInputDisabled] = useState(false);

  const [formData, setFormData] = useState({
    activeInd: true,
    contextId: glbContextId,
    objectId: '',
    objectElementId: '',
    ruleSequence: '',
    validateVisualTag: '',
    redact: '',
    redactionType: '',
    conditionalRuleId: '',
    defaultRedactionReason: '',
    defaultRedactionReasonLabel: '',
    redactionFormalReference: '',
    redactionString: '',
    elementName: '',
  });

  async function fetchRedactionDefinitionData() {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
      contextId: glbContextId,
    };
    await searchRedactionDefinition(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchRedactionDefinitionData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: '',
      order: '',
      contextId: glbContextId,
    };
    setSorting({ column: '', order: '' });
    await searchRedactionDefinition(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSearch = async (searchKeyword) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
      contextId: glbContextId,
    };
    await searchRedactionDefinition(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      activeInd: true,
      contextId: glbContextId,
      objectId: '',
      objectElementId: '',
      ruleSequence: '',
      validateVisualTag: '',
      redact: '',
      redactionType: '',
      conditionalRuleId: '',
      defaultRedactionReason: '',
      defaultRedactionReasonLabel: '',
      redactionFormalReference: '',
      redactionString: '',
      elementName: '',
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const editFunc = async (data) => {
    setFormData({
      activeInd: data.activeInd,
      contextId: data.contextId,
      objectId: data.objectId,
      objectElementId: data.objectElementId,
      ruleSequence: data.ruleSequence,
      validateVisualTag: data.validateVisualTag,
      redact: data.redact,
      redactionType: data.redactionType,
      conditionalRuleId: data.conditionalRuleId,
      defaultRedactionReason: data.redactionReasonContract?.redactionReason,
      defaultRedactionReasonLabel: data.redactionReasonContract?.redactionReasonText,
      redactionFormalReference: data.redactionFormalReference,
      redactionString: data.redactionString,
      elementName: data.elementName,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchKeyword('');
    setSorting({ column: '', order: '' });
    await fetchNewRedactionDefinitionData();
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRoutingStepsData = JSON.parse(JSON.stringify(redactionDefinitionDetails));
    newRoutingStepsData.forEach((itm) => {
      itm.sortType = '';
    });
    setRedactionDefinitionData(newRoutingStepsData);
  };

  // Code needs to change
  const fetchNewRedactionDefinitionData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
      contextId: glbContextId,
    };
    await searchRedactionDefinition(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchKeyword,
      contextId: glbContextId,
    };
    await searchRedactionDefinition(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKeyword(value.trimStart());
  };

  useEffect(() => {
    if (searchKeyword.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchKeyword);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchKeyword.length === 0) {
      onSearch(searchKeyword);
    }
  }, [searchKeyword]);

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      contextId: glbContextId,
      objectId: DeleteData.objectId,
      objectElementId: DeleteData.objectElementId,
      ruleSequence: DeleteData.ruleSequence,
      id: DeleteData.id,
    };
    deleteRedactionDefinition(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await onSearch(searchKeyword);
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Redaction Definition deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  return (
    //Parent Component
    <div data-testid="redactionDefinition-container" className={styles.redactionDefinitionPage}>
      {isLoading && <GlobalLoader />}
      <Breadcrumb data={breadcrumbData} />

      <div data-testid="redactionDefinition-page-wrapper" className={styles.redactionDefinitionPageContent}>
        <h1 data-testid="redactionDefinition-heading" className={styles.pageTitle}>
          Redaction Definition
        </h1>
        <div>
          <div data-testid="redactionDefinition-search-container" className={styles.searchSection}>
            <div data-testid="redactionDefinition-search-label" className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div className={styles.searchOuterContainer}>
              <div data-testid="redactionDefinition-input-container" className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchKeyword}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId={'search-redactionDefinition'}
                ></SearchAdminPanelComponent>
              </div>
              <div data-testid="redactionDefinition-create-btn-container">
                <CustomButton testId={'create-redactionDefinition-btn'} title="Create Redaction Definition" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span data-testid="redactionDefinition-help-text" className="searchNoteStyle">
              {' '}
              Note: Entering 3 letters enables search capabilities.
            </span>
          </div>
        </div>
        <div data-testid="redactionDefinition-table-container" className={[styles.redactionDefinitionTable, 'glbRedactionDefinitionTable'].join(' ')}>
          <TableRedactionDefinition
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={redactionDefinitionDetails}
            onPageChange={onPageChange}
            searchKeyword={searchKeyword}
            // setSelectedRows={setSelectedRows}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>

        <AddEditRedactionDefinitionModal
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          fetchRedactionDefinitionData={() => onSearch(searchKeyword)}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
          glbContextId={glbContextId}
        />

        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            deleteData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            selectedDeleteRowData={deleteData}
            popupTitle={'Redaction Definition'}
          ></DeleteModal>
        )}

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functioanlity
const AddEditRedactionDefinitionModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(true);
  const [objectElementIds, setObjectElementIds] = useState([]);
  const [defaultRedactionReasonList, setDefaultRedactionReasonList] = useState([]);
  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);

  useEffect(() => {
    // fetchObjectElementIds();
    // fetchDefaultRedactionReason();
  }, []);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const fetchObjectElementIds = async () => {
    let payload = {
      contextId: JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
    };
    await getElementIdList(payload).then((response) => {
      setObjectElementIds(response?.data?.map((itm) => itm.objectElementId));
    });
  };
  // Note: change api
  const fetchDefaultRedactionReason = async () => {
    let payload = {
      contextId: JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
    };
    await getObjectIds(payload).then((response) => {
      setDefaultRedactionReasonList(response.data);
    });
  };

  const loadObjectElementIdList = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let payload = { contextId: props.formData.contextId, text: ' ' };
      // await response.data.
      await getDefaultElement(payload).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          options.push({ label: res.elementName, value: res.objectElementId });
          setTimeout(() => {
            callback(options);
          }, 1000);
        });
        callback(options);
      });
    } else {
      let payload = { contextId: props.formData.contextId, text: inputValue };
      await getDefaultElement(payload).then(async (response) => {
        let options = await response.data.map((res) => {
          return { label: res.elementName, value: res.objectElementId };
        });

        callback(options);
      });
    }
  };

  // changed the function because we need a searchable dropdown
  // const loadDefaultRedactionReason = async (inputValue, callback) => {
  //   if (inputValue === '') {
  //     callback([]);
  //     let options = [];
  //     defaultRedactionReasonList.map((res) => {
  //       options.push({ label: res, value: res });
  //       setTimeout(() => {
  //         callback(options);
  //       }, 1000);
  //     });
  //     callback(options);
  //   } else {
  //     let options = await defaultRedactionReasonList.map((res) => {
  //       return { label: res, value: res };
  //     });
  //     callback(options);
  //   }
  // };

  const loadDefaultRedactionReason = async (inputValue, callback) => {
    let payload = {
      contextId: props.glbContextId,
      inputValue: inputValue,
    };
    if (inputValue === '') {
    } else {
      await searchDefaultRedactionReason(payload).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.redactionReasonText, value: res.redactionReason };
        });

        callback(options);
      });
    }
  };

  const loadValidateVisualTag = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let options = [];
      validateVisualTagData.map((res) => {
        options.push({ label: res, value: res });
        setTimeout(() => {
          callback(options);
        }, 1000);
      });
      callback(options);
    } else {
      let options = await validateVisualTagData.map((res) => {
        return { label: res, value: res };
      });
      callback(options);
    }
  };

  const loadRedactData = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let options = [];
      redactData.map((res) => {
        options.push({ label: res, value: res });
        setTimeout(() => {
          callback(options);
        }, 1000);
      });
      callback(options);
    } else {
      let options = await redactData.map((res) => {
        return { label: res, value: res };
      });
      callback(options);
    }
  };

  const loadRedactionTypeData = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let options = [];
      redactionTypeData.map((res) => {
        options.push({ label: res, value: res });
        setTimeout(() => {
          callback(options);
        }, 1000);
      });
      callback(options);
    } else {
      let options = await redactionTypeData.map((res) => {
        return { label: res, value: res };
      });
      callback(options);
    }
  };

  const loadConditionalRuleIdData = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let options = [];
      conditionalRuleIdData.map((res) => {
        options.push({ label: res, value: res });
        setTimeout(() => {
          callback(options);
        }, 1000);
      });
      callback(options);
    } else {
      let options = await conditionalRuleIdData.map((res) => {
        return { label: res, value: res };
      });
      callback(options);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value ? value.trimStart() : value,
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      activeInd: booleanValue,
    }));
  };

  const handleSubmit = async (data) => {
    if (!data.objectElementId || !data.ruleSequence || (data.redactionType === 'String' && data.redactionString === '')) setisRequiredError(true);
    else {
      props.isLoading(true);
      let payload = _.cloneDeep(data);
      await createRedactionDefinition(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);

          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Redaction Definition created successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);

          props.fetchRedactionDefinitionData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const handleEdit = async (data) => {
    if (!data.objectElementId || !data.ruleSequence || (data.redactionType === 'String' && data.redactionString === '')) setisRequiredError(true);
    else {
      let payload = _.cloneDeep(data);

      props.isLoading(true);

      await updateOneRedactionDefinition(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);
          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Redaction Definition updated successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);
          props.fetchRedactionDefinitionData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const onChangeObjectId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        objectId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      objectId: updatedValue,
    }));
  };

  const onChangeObjectElementId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        objectElementId: '',
        elementName: '',
      }));
    const { value, label } = event;
    const updatedValue = value === '' ? null : value;
    const updatedLabel = label === '' ? null : label;
    props.setFormData((prevData) => ({
      ...prevData,
      objectElementId: updatedValue,
      elementName: updatedLabel,
    }));
  };

  const onChangeValidateVisualTag = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        validateVisualTag: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      validateVisualTag: updatedValue,
    }));
  };

  const onChangeRedact = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        redact: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      redact: updatedValue,
    }));
  };

  const onChangeRedactionType = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        redactionType: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      redactionType: updatedValue,
    }));
  };

  const onChangeConditionalRuleId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        conditionalRuleId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      conditionalRuleId: updatedValue,
    }));
  };

  const onChangeRedactionReason = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        defaultRedactionReason: '',
        defaultRedactionReasonLabel: '',
      }));
    const { value, label } = event;
    const updatedValue = value === '' ? null : value;
    const updatedLabel = label === '' ? '' : label;
    props.setFormData((prevData) => ({
      ...prevData,
      defaultRedactionReason: updatedValue,
      defaultRedactionReasonLabel: updatedLabel,
    }));
  };

  const handleCancel = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };
  const handleModalCrossIconBtn = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };

  return (
    <Modal
      {...props}
      className={[styles.redactionDefinitionModal, 'glbRedactionDefinitionModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Redaction Definition' : 'Edit Redaction Definition'}</h2>
            </div>
            <div className={styles.modalHeaderIcon} onClick={() => handleModalCrossIconBtn()}>
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className={styles.redactionDefinitionModalBody}>
          <div className={styles.modalBodySection}>
            {props.isNew === false && (
              <div className={styles.inputFieldSection}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Context ID *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    // loadOptions={loadObjectsList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                    // onChange={onChangeObjectId}
                    isSearchable={false}
                    defaultOptions
                    className={props.isNew ? '' : styles.disabledInput}
                  />
                </div>
              </div>
            )}

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Element Name *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  isDisabled={props.isNew ? false : true}
                  loadOptions={loadObjectElementIdList}
                  value={props?.formData?.elementName !== '' ? { label: props?.formData?.elementName, value: props?.formData?.objectElementId } : null}
                  onChange={onChangeObjectElementId}
                  isSearchable={true}
                  defaultOptions
                  className={props.isNew ? '' : styles.disabledInput}
                />
                {(props.formData.elementName === null || props.formData.elementName === '') && isRequiredError === true && handleIsRequiredError()}
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Rule Sequence *</label>
                <input
                  required
                  name="ruleSequence"
                  value={props.formData.ruleSequence}
                  disabled={props.isNew ? false : true}
                  onChange={handleInputChange}
                  className={props.isNew ? '' : styles.disabledInput}
                  type="number"
                  onKeyDown={(e) => ['e', 'E'].includes(e.key) && e.preventDefault()}
                />
                {(props.formData.ruleSequence === null || props.formData.ruleSequence === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Validate Visual Tag</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadValidateVisualTag}
                  value={props?.formData?.validateVisualTag !== '' ? { label: props?.formData?.validateVisualTag, value: props?.formData?.validateVisualTag } : null}
                  onChange={onChangeValidateVisualTag}
                  isSearchable={false}
                  defaultOptions
                />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Redact</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadRedactData}
                  value={props?.formData?.redact !== '' ? { label: props?.formData?.redact, value: props?.formData?.redact } : null}
                  onChange={onChangeRedact}
                  isSearchable={false}
                  defaultOptions
                />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>RedactionType</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadRedactionTypeData}
                  value={props?.formData?.redactionType !== '' ? { label: props?.formData?.redactionType, value: props?.formData?.redactionType } : null}
                  onChange={onChangeRedactionType}
                  isSearchable={false}
                  defaultOptions
                />
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Redaction String {props?.formData?.redactionType === 'Default' || props?.formData?.redactionType === '' ? '' : '*'}</label>
                <textarea
                  name="redactionString"
                  required
                  value={props.formData.redactionString}
                  onChange={handleInputChange}
                  disabled={props?.formData?.redactionType === 'Default' || props?.formData?.redactionType === '' ? true : false}
                />

                {props?.formData?.redactionType === 'Default' || props?.formData?.redactionType === '' ? (
                  ''
                ) : (
                  <>
                    {(props.formData.redactionString === null || props.formData.redactionString === '') && isRequiredError === true && handleIsRequiredError()}
                    {props.formData.redactionString?.length > 500 && handleMaxLimitError(500)}
                  </>
                )}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Conditional Rule ID</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadConditionalRuleIdData}
                  value={props?.formData?.conditionalRuleId !== '' ? { label: props?.formData?.conditionalRuleId, value: props?.formData?.conditionalRuleId } : null}
                  onChange={onChangeConditionalRuleId}
                  isSearchable={false}
                  defaultOptions
                />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Default Redaction Reason</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadDefaultRedactionReason}
                  value={
                    props?.formData?.defaultRedactionReason !== '' ? { label: props?.formData?.defaultRedactionReasonLabel, value: props?.formData?.defaultRedactionReason } : null
                  }
                  onChange={onChangeRedactionReason}
                  // isSearchable={false}
                  defaultOptions
                />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Redaction Formal Reference</label>
                <input name="redactionFormalReference" required value={props.formData.redactionFormalReference} onChange={handleInputChange} />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.radioInputOuterDiv}>
                  Active Ind: <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} /> True
                  <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.redactionDefinitionFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => handleSubmit(props.formData)} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => handleEdit(props.formData)} />
            )}
            <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => handleCancel()} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
