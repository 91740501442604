import React, { useState, useEffect } from 'react';
import './ScanOnDemand.scss';
import filesIcon from '../../fsa-style/img/files-icon.svg';
import { uploadBuowFile, getFileData } from '../../services/inBasketService';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/Spinner.css';

const SignInPage = () => {
  const [file, setFile] = useState();
  const [droppedFile, setDroppedFile] = useState();
  const hiddenFileInput = React.useRef(null);
  const [fileUploadMsg, setFileUploadMsg] = useState();
  const [progress, setProgress] = useState(0);
  const [fileData, setFileData] = useState([]);
  const [fileloading, setFileloading] = useState(false);

  useEffect(() => {
    async function data() {
      setFileloading(true);
      await getFileData().then((response) => {
        setFileData(response.data);
        setFileloading(false);
      });
    }
    data();
  }, []);

  const onRefreshData = async () => {
    setFileloading(true);
    await getFileData().then((response) => {
      setFileData(response.data);
      setFileloading(false);
      hiddenFileInput.current.value = null;
      setDroppedFile('');
      setFile('');
    });
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      onUploadFile(e.target.files);
    }
  };
  const onUploadFile = (fileData) => {
    setFile(fileData[0]);
    setFileloading(true);
    let formData = new FormData();
    formData.append('file', fileData[0]);
    let percent;

    let progressBarData = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          setProgress(percent);
        }
      },
    };
    uploadBuowFile(formData, progressBarData).then((response) => {
      if (response) {
        setFileUploadMsg('File saved successfully.');
        setProgress(100);
        onRefreshData();
      }
    });
  };

  const handleUploadButton = () => {
    hiddenFileInput.current.click();
  };

  // Need to check code
  const dragOver = (e) => {
    e.preventDefault();
  };
  const dragEnter = (e) => {
    e.preventDefault();
  };
  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      setDroppedFile(e.dataTransfer.files[0]);
      handleFiles(files);
      onUploadFile(e.dataTransfer.files);
    }
  };

  const validateFile = (file) => {
    let ext = file.name.split('.').pop();
    const validTypes = ['pdf', 'docx', 'doc'];
    if (validTypes.indexOf(ext) === -1) return false;
    return true;
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
      } else {
        files[i]['invalid'] = true;
      }
    }
  };

  return (
    <main className="w-100 m-0" tabIndex="-1">
      <div className="content">
        <div className="scan-outer-div mt-4">
          <div className="scan-inner-div d-flex">
            <div>
              <div className="upload-file-section">
                <input
                  type="file"
                  onChange={handleFileChange}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  style={{ display: 'none' }}
                  ref={hiddenFileInput}
                />
                <button onClick={handleUploadButton} className="fsa-btn upload-btn">
                  <span className="k-icon k-i-upload"></span>
                  Upload
                </button>
                <div>{file && `${file.name}`}</div>
                <br />
                <div>OR</div> <br />
                <div onDragOver={dragOver} onDragEnter={dragEnter} onDragLeave={dragLeave} onDrop={fileDrop} className="drop-files-section">
                  <img src={filesIcon} alt="filesIcon" />
                  <div className="mt-3">{droppedFile ? `${droppedFile.name}` : 'Drag and Drop files here'}</div>
                </div>
              </div>

              <div style={{ margin: '10px' }}>
                <ProgressBar variant="info" now={progress} />

                <div className="d-flex justify-content-center mt-3">{fileUploadMsg && <span className="file-upload-msg">{fileUploadMsg}</span>}</div>
              </div>
            </div>

            <div className="d-flex">
              <div className="file-upload-table ml-5">
                <div className="refresh-icon-outer-div">
                  <i className="fa fa-refresh" aria-hidden="true" onClick={onRefreshData}></i>
                </div>

                <TableData data={fileData} fileloading={fileloading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

const TableData = (props) => {
  return (
    <>
      <div className="container" style={props.fileloading ? { opacity: '0.4' } : {}}>
        {props.fileloading && (
          <div className="spinner-outer-div d-flex justify-content-center">
            <Spinner color="#000" size={40} speed={1} animating={true} />
          </div>
        )}

        <table className="border-black fileTable">
          <tbody className="border-black">
            <>
              <table>
                <tr>
                  <th> File Id </th>
                  <th> File Url </th>
                  <th> Added On </th>
                  <th> Processed </th>
                </tr>

                {/* <tbody> */}
                {props.data.map((dataObj, index) => (
                  <tr>
                    <td>{dataObj.fileId}</td>
                    <td>{dataObj.fileUrl}</td>
                    <td>{dataObj.addedOn}</td>
                    <td>{dataObj.processed === false ? 'Processing' : 'Completed'}</td>
                  </tr>
                ))}
                {/* </tbody> */}
              </table>
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default SignInPage;
