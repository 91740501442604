import axios from 'axios';

export async function saveObjectHierarchy(data) {
  let createResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/objecthierarchy';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        createResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.response.data);
    createResponse.error = ex.response.data;
  }
  // console.log(createResponse);
  return createResponse;
}

export async function updateObjectHierarchy(data) {
  let updateResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + `/buow_api/objecthierarchy?contextId=${data.contextId}&objectClass=${data.objectClass}`;
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deleteObjectHierarchy(data) {
  let deleteResponse = [];
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/buow_api/objecthierarchy?contextId=${data.contextId}&objectClass=${data.objectClass}`;
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function searchObjectHierarchy(data) {
  let workQueueFilterResponse = [];
  try {
    let url = process.env.REACT_APP_BACKEND_URL + `/buow_api/objecthierarchy/search?contextId=${data.contextId}`;
    if (data.text !== '' && data.column === '' && data.order === '') {
      url += '&keywords=' + data.text;
    } else {
      url += '&keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function searchParentObjectClass(data) {
  let workQueueFilterResponse = [];
  try {
    let url = process.env.REACT_APP_BACKEND_URL + `/buow_api/objecthierarchy/objectclass/${data.inputValue}?contextId=${data.contextId}`;

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
