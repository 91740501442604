import React, { useState, useEffect, lazy, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import './AdminSearchPage.scss';
import _ from 'lodash';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader';
import FetchLocalStorageDataByPath from '../../../utils/helper/FetchLocalstorageDataByPath';

import SystemFolder from '../../../fsa-style/img/svgs/SystemFolder.svg';
import UserPreference from '../../../fsa-style/img/svgs/UserPreference.svg';
import UserGuide from '../../../fsa-style/img/svgs/User_guide2.svg';
import InBasketIcon from '../../../fsa-style/img/svgs/InBasketIcon.svg';
import UnitOfWorkIcon from '../../../fsa-style/img/svgs/UnitOfWorkIcon.svg';
import MessageIcon from '../../../fsa-style/img/svgs/MessageIcon.svg';
import WorkgroupUserXwalkIcon from '../../../fsa-style/img/svgs/WorkgroupUserXwalkIcon.svg';
import RoutingOrchestrationIcon from '../../../fsa-style/img/svgs/RoutingOrchestrationIcon.svg';
import RuleDefinitionIcon from '../../../fsa-style/img/svgs/RuleDefinitionIcon.svg';
import RolesTableIcon from '../../../fsa-style/img/svgs/rolesTable.svg';
import PermissionsTableIcon from '../../../fsa-style/img/svgs/permissionsTable.svg';
import UsersTableIcon from '../../../fsa-style/img/svgs/usersTable.svg';
import BuowCrudIcon from '../../../fsa-style/img/svgs/BuowCrud.svg';
import RuleTriggerIcon from '../../../fsa-style/img/svgs/RuleTriggerIcon.svg';
import RuleLog from '../../../fsa-style/img/svgs/RuleLog.svg';
import WorkgrupIcon from '../../../fsa-style/img/svgs/WorkgrupIcon.svg';
import RoutingStepsIcon from '../../../fsa-style/img/svgs/RoutingStepsIcon.svg';
import SystemConfigurationIcon from '../../../fsa-style/img/svgs/SystemConfigurationIcon.svg';
import BUOWDisplayNameIcon from '../../../fsa-style/img/svgs/BUOWDisplayNameLogo.svg';
import { fixedPermissions } from '../../../utils/helper/permissionFinder';
import UserContext from '../../../components/UserContext/UserContext';

//with lazy

const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));

// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';

const AdministratorPanel = () => {
  let roleName = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.rolesName;
  const { userPermissions, setUserPermissions } = useContext(UserContext);
  const newCards = [
    {
      id: 1,
      displaySection: 'Admin Functions',
      cardsToRender: [
        {
          id: 1,
          title: 'System Configuration',
          buttonName: 'View More ',
          pageUrl: '/systemconfiguration',
          icon: SystemConfigurationIcon,
        },
        {
          id: 2,
          title: 'System Folder',
          buttonName: 'View More ',
          pageUrl: '/system-folder',
          icon: SystemFolder,
        },
        {
          id: 3,
          title: 'In Basket',
          buttonName: 'View More ',
          pageUrl: '/inbaskettable',
          icon: InBasketIcon,
        },
        { id: 4, title: 'Messages', buttonName: 'View More ', pageUrl: '/messages', icon: MessageIcon },
        {
          id: 5,
          title: 'User Guide',
          buttonName: 'View More',
          pageUrl: '/userguide',
          icon: UserGuide,
        },
      ],
    },
    {
      id: 2,
      displaySection: 'User Management',
      cardsToRender: [
        {
          title: 'Permissions',
          buttonName: 'View More',
          pageUrl: '/permissionstable',
          icon: PermissionsTableIcon,
          id: 1,
        },
        {
          title: 'Roles',
          buttonName: 'View More ',
          pageUrl: '/rolestable',
          icon: RolesTableIcon,
          id: 2,
        },
        {
          title: 'Users',
          buttonName: 'View More ',
          pageUrl: '/userstable',
          icon: UsersTableIcon,
          id: 3,
        },
        {
          title: 'User Preference',
          buttonName: 'View More ',
          pageUrl: '/user-preference',
          icon: UserPreference,
          id: 4,
        },
      ],
    },
    {
      id: 3,
      displaySection: 'Business Process Management',
      cardsToRender: [
        {
          title: 'Rule Definition',
          buttonName: 'View More ',
          pageUrl: '/ruledefinition',
          icon: RuleDefinitionIcon,
          id: 1,
        },
        {
          title: 'Routing Orchestration',
          buttonName: 'View More ',
          pageUrl: '/orchestration',
          icon: RoutingOrchestrationIcon,
          id: 2,
        },
        {
          title: 'Routing Steps',
          buttonName: 'View More ',
          pageUrl: '/routingsteps',
          icon: RoutingStepsIcon,
          id: 3,
        },
        {
          title: 'Rule Trigger',
          buttonName: 'View More ',
          pageUrl: '/ruletrigger',
          icon: RuleTriggerIcon,
          id: 4,
        },
        {
          title: 'Rule Log',
          buttonName: 'View More ',
          pageUrl: '/rule-log',
          icon: RuleLog,
          id: 5,
        },
        {
          title: 'Routing Conditions',
          buttonName: 'View More ',
          pageUrl: '/routingconditions',
          icon: WorkgroupUserXwalkIcon,
          id: 6,
        },
      ],
    },
    {
      id: 4,
      displaySection: 'Object Management',
      cardsToRender: [
        {
          title: 'Object Definition',
          buttonName: 'View More ',
          pageUrl: '/objectdefinitiontable',
          icon: WorkgroupUserXwalkIcon,
          id: 1,
        },
        {
          title: 'Object Dictionary Object',
          buttonName: 'View More ',
          pageUrl: '/objectdictionaryobject',
          icon: RuleLog,
          id: 2,
        },
        {
          title: 'Object Dictionary Object Type',
          buttonName: 'View More ',
          pageUrl: '/objectdictionaryobjecttype',
          icon: RuleLog,
          id: 3,
        },
        {
          title: 'Object Dictionary Object Definition',
          buttonName: 'View More ',
          pageUrl: '/objectdefinition',
          icon: RuleLog,
          id: 4,
        },
        {
          title: 'Object Dictionary Object Hierarchy',
          buttonName: 'View More ',
          pageUrl: '/object-hierarchy-table',
          icon: RuleLog,
          id: 5,
        },
      ],
    },
    {
      id: 5,
      displaySection: 'BUOW Configuration',
      cardsToRender: [
        {
          title: 'BUOW',
          buttonName: 'View More ',
          pageUrl: '/buowtable',
          icon: BuowCrudIcon,
          id: 1,
        },
        {
          title: 'BUOW Display Name',
          buttonName: 'View More ',
          pageUrl: '/buowdisplayname',
          icon: BUOWDisplayNameIcon,
          id: 2,
        },
        {
          title: 'Workgroup User Xwalk',
          buttonName: 'View More ',
          pageUrl: '/workgroupuserxwalk',
          icon: WorkgroupUserXwalkIcon,
          id: 3,
        },
        {
          title: 'Unit of Work',
          buttonName: 'View More ',
          pageUrl: '/unit-of-work',
          icon: UnitOfWorkIcon,
          id: 4,
        },
        {
          title: 'Workgroup',
          buttonName: 'View More ',
          pageUrl: '/work-group',
          icon: WorkgrupIcon,
          id: 5,
        },
      ],
    },
    {
      id: 6,
      displaySection: 'Batch Processing Configuration',
      cardsToRender: [
        {
          title: 'Batch Definition',
          buttonName: 'View More ',
          pageUrl: '/batchdefinition',
          icon: WorkgroupUserXwalkIcon,
          id: 1,
        },
        {
          title: 'Packager Configuration',
          buttonName: 'View More ',
          pageUrl: '/packager-configuration',
          icon: WorkgroupUserXwalkIcon,
          id: 2,
        },
        {
          title: 'Packager Preferred Order',
          buttonName: 'View More ',
          pageUrl: '/packager-preferred-order',
          icon: WorkgroupUserXwalkIcon,
          id: 3,
        },
        {
          title: 'Batch Xwalk',
          buttonName: 'View More ',
          pageUrl: '/batch-xwalk',
          icon: WorkgroupUserXwalkIcon,
          id: 4,
        },
      ],
    },
    {
      id: 7,
      displaySection: 'Redaction Configuration',
      cardsToRender: [
        {
          title: 'Redaction Definition',
          buttonName: 'View More ',
          pageUrl: '/redactiondefinition',
          icon: WorkgroupUserXwalkIcon,
          id: 1,
        },
        {
          title: 'Redaction Reason',
          buttonName: 'View More ',
          pageUrl: '/redaction-reason',
          icon: RuleTriggerIcon,
          id: 2,
        },
        {
          title: 'Redaction Actor',
          buttonName: 'View More ',
          pageUrl: '/redaction-actor',
          icon: RuleTriggerIcon,
          id: 3,
        },
        {
          title: 'Redaction History',
          buttonName: 'View More ',
          pageUrl: '/redactionhistorytable',
          icon: RuleLog,
          id: 4,
        },
      ],
    },
    {
      id: 8,
      displaySection: 'Dashboard',
      cardsToRender: [
        {
          title: 'Dashboard Indicators Group',
          buttonName: 'View More ',
          pageUrl: '/dashboard-indicators-group',
          icon: WorkgroupUserXwalkIcon,
          id: 1,
        },
        {
          title: 'Dashboard Group Card XRef',
          buttonName: 'View More ',
          pageUrl: '/dashboard-group-card-xref',
          icon: WorkgroupUserXwalkIcon,
          id: 2,
        },
        {
          title: 'Dashboard Indicators DataSet Card XRef',
          buttonName: 'View More ',
          pageUrl: '/dashboard-indicators-dataSet-card-xref',
          icon: WorkgroupUserXwalkIcon,
          id: 3,
        },
        {
          title: 'Dashboard Indicators',
          buttonName: 'View More ',
          pageUrl: '/dashboard-indicators',
          icon: WorkgroupUserXwalkIcon,
          id: 4,
        },
        {
          title: 'Dashboard Card Benchmark Display',
          buttonName: 'View More ',
          pageUrl: '/dashboard-card-benchmark-display',
          icon: WorkgroupUserXwalkIcon,
          id: 5,
        },
        {
          title: 'Indicators Data Set',
          buttonName: 'View More ',
          pageUrl: '/indicators-data-set',
          icon: WorkgroupUserXwalkIcon,
          id: 6,
        },
        {
          title: 'Sec Role Dashboard Card Xref',
          buttonName: 'View More ',
          pageUrl: '/sec-role-dashboard-card-xref',
          icon: WorkgroupUserXwalkIcon,
          id: 7,
        },
      ],
    },
  ];

  const [cardData, setCardData] = useState([]);
  const history = useHistory();
  const [filteredCardData, setFilteredCardData] = useState(newCards);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    renderPermittedCards();
  }, []);
  const handleCardButton = (pageUrl) => {
    history.push(pageUrl);
  };

  useEffect(() => {
    async function data() {
      findPermissions();
    }
    // if (urlChanged === true) {
    data();
    // }
  }, []);
  const findPermissions = async () => {
    let x = JSON.parse(sessionStorage.getItem('user_information')) !== undefined ? JSON.parse(sessionStorage.getItem('user_information'))?.rolePermissions : [];
    let obj = {};
    if (x !== undefined && x.length > 0) {
      await x.map((i) => {
        if (fixedPermissions.includes(i) === true) {
          obj[i] = true;
        }
        return null;
      });

      setUserPermissions(obj);
    }
  };

  const renderPermittedCards = async () => {
    try {
      const fetchedLocalStorageData = FetchLocalStorageDataByPath('system_config', 'adminPanel');

      let filteredCardsData = await fetchedLocalStorageData.filter((itm) => {
        if (itm.value === true) return itm;
      });

      let isSuperUser = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.superUser;
      const isAuthorized = roleName?.includes('Admin');
      const isRedactionAdmin = roleName?.includes('Redaction Admin');

      let data = await Promise.all(
        newCards.map(async (values) => {
          let filteredCards = values.cardsToRender.filter((itm) => {
            let itemFound = _.find(filteredCardsData, { title: itm.title });
            return itemFound && itemFound.title !== 'System Configuration' && itemFound.title !== 'System Folder';
          });
          let result = { ...values, cardsToRender: filteredCards };
          if (values.displaySection.toLowerCase().replace(/\s+/g, '') === 'adminfunctions') {
            if (isSuperUser) {
              result.cardsToRender.push({
                title: 'System Configuration',
                buttonName: 'View More',
                pageUrl: '/systemconfiguration',
                icon: SystemConfigurationIcon,
                id: 1,
              });
            }

            if (isSuperUser || isAuthorized) {
              result.cardsToRender.push({
                title: 'System Folder',
                buttonName: 'View More',
                pageUrl: '/system-folder',
                icon: SystemFolder,
                id: 2,
              });
            }

            if (process.env.REACT_APP_GUIDE_VISIBLE === 'true' || process.env.REACT_APP_GUIDE_VISIBLE === true) {
              result.cardsToRender.push({
                title: 'User Guide',
                buttonName: 'View More',
                pageUrl: '/userguide',
                icon: UserGuide,
                id: 5,
              });
            }
          }
          if (values.displaySection.toLowerCase().replace(/\s+/g, '') === 'redactionconfiguration') {
            if (isRedactionAdmin) {
              result.cardsToRender.push({
                title: 'Redaction History',
                buttonName: 'View More',
                pageUrl: '/redactionhistorytable',
                icon: RuleLog,
                id: 4,
              });
            }
          }

          return result;
        })
      );

      setCardData(data);
      setFilteredCardData(data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const renderCard = (cardsData) => {
    const userInfo = JSON.parse(sessionStorage.getItem('user_information'));
    const userRoles = userInfo?.rolesName || [];

    const shouldRenderCard = (card) => {
      const cardTitle = card.title.replace(/\s/g, '_');

      return (
        userPermissions[cardTitle] === true
        // (userRoles.includes('Application Administrator') && card.title === 'Users') ||
        // userRoles.includes('Admin') ||
        // (userRoles.includes('Redaction Admin') && card.title === 'Redaction History')
      );
    };

    const filteredCards = cardsData.cardsToRender.filter(shouldRenderCard).sort((a, b) => a.id - b.id);

    return filteredCards.length > 0 ? (
      <div className="flex-column">
        <h3 className="mx-5">{cardsData.displaySection}</h3>
        <div className="d-flex flex-wrap">
          {filteredCards.map((card) => (
            <section className="col-3 card-section" key={`${card.id}_admin_card`}>
              <div className="card admin-panel-card mt-2 mb-3">
                <div className="card-circle mx-auto white">
                  <div className="dashboardIconOuterWhite">
                    <div className="dashboardIconOuter">
                      <svg xmlns="http://www.w3.org/2000/svg" width="113" height="113" viewBox="0 0 113 113" fill="none">
                        <circle cx="56.5" cy="56.5" r="56.5" fill="white" />
                      </svg>
                      <img className={card.icon === UserGuide ? 'userGuideIcon' : 'card-icon'} src={card.icon} alt="icon" />
                    </div>
                  </div>
                </div>
                <div className="card-body text-center">
                  <h4 className="card-title">{card.title}</h4>
                  <div className="horizontalBar">
                    <span>
                      <hr className="horizontalBarContent" />
                    </span>
                  </div>
                  <h6 className="card-button-name">
                    <CustomButton className="admin-panel-button" parentWarpper={'w-100'} title={card.buttonName} onClick={() => handleCardButton(card.pageUrl)} />
                  </h6>
                </div>
              </div>
            </section>
          ))}
        </div>
      </div>
    ) : (
      <></>
    );
  };
  return (
    <>
      <main className="main-admin-panel">
        {isLoading && <GlobalLoader />}

        <div>
          {/* <div className="row col-md-12 mt-5"> */}
          {filteredCardData.map((card, index) => (
            <React.Fragment key={`${index}_admin_panels_cruds`}>
              {/* {JSON.parse(sessionStorage.getItem('user_information')).rolesName?.includes('Application Administrator') === true && card.title === 'Users' && renderCard(card)} */}
              {renderCard(card)}
            </React.Fragment>
          ))}
        </div>
        {/* </div> */}
      </main>
    </>
  );
};

export default AdministratorPanel;
