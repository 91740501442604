import React, { useEffect, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import '../../../GlobalStyle.scss';
import { searchBuow, updateBuow } from './BuowService';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import { handleMaxLimitError, handleCustomErrorMsg } from '../../../utils/helper/handleReuiredErrorFunc.js';
import { handleAllowPositiveNumbers } from '../../../utils/helper/helperFunctions.js';
import buowTablesData from './BuowData.json';
import styles from './Buow.module.scss';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';

// with lazy

const BuowTable = lazy(() => import('./BuowTable.js'));
//Resusable Components Start
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl.js'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl.js'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb.js'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton.js'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js'));

//w/o lazy
// import BuowTable from './BuowTable.js';

// //Resusable Components Start
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl.js';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl.js';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb.js';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton.js';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js';
// import Footer from '../../../newcomponents/Footer/Footer';
//Resusable Components End

var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'BUOW', link: '' },
];

export default function Buow() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    contextId: '',
    buowType: '',
    buowName: '',

    buowCreateDttm: '',
    buowSubject: '',
    containerId: '',
    workflowState: '',

    kpi1: '',
    kpi2: '',
    kpi3: '',
    kpi4: '',
    additionalMetadata: '',
    activeInd: true
  });
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [buowTableDetails, setbuowTableDetails] = useState(buowTablesData);

  async function fetchbuowData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchBuow(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchbuowData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchBuow(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const editFunc = (data) => {
    console.log(data);
    setFormData({
      id: data.id,
      contextId: data.contextId,
      buowType: data.buowType,
      buowName: data.buowName,
      buowCreateDttm: data.buowCreateDttm,
      workflowState: data.workflowState,
      buowSubject: data.buowSubject,
      containerId: data.containerId,
      kpi1: data.kpi1,
      kpi2: data.kpi2,
      kpi3: data.kpi3,
      kpi4: data.kpi4,
      additionalMetadata: data.additionalMetadata,
      activeInd: data.activeInd === null || data.activeInd === undefined ? true : data.activeInd,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchBuow(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newBuowTableData = JSON.parse(JSON.stringify(buowTableDetails));
    newBuowTableData.forEach((itm) => {
      itm.sortType = '';
    });
    // setbuowTableDetails(newBuowTableData);
    await fetchnewBuowTableData();
  };

  // Code needs to change
  const fetchnewBuowTableData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchBuow(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchBuow(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.buowTablePage} data-testid="buow-crud-container">
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.buowTablePageContent}>
        <h1 className={styles.pageTitle}>BUOW</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div data-testid="keyword-search-container" className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId="keyword-search"
                ></SearchAdminPanelComponent>
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.buowTableTable, 'glbbuowTable'].join(' ')}>
          <BuowTable
            data={data}
            page={page}
            editFunc={editFunc}
            pageSize={pageSize}
            fields={buowTableDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <EditBuowTable
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchbuowData={fetchbuowData}
        />

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

// START:- Edit record modal with functionality
const EditBuowTable = (props) => {
  // const onChangeContextId = (event) => {
  //   if (event == null)
  //     return props.setFormData((prevData) => ({
  //       ...prevData,
  //       contextId: userInfoContext,
  //     }));
  //   const { value } = event;
  //   const updatedValue = value === '' ? null : value;
  //   props.setFormData((prevData) => ({
  //     ...prevData,
  //     contextId: updatedValue,
  //   }));
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value ? value.trimStart() : value,
    }));
  };

  const handleEdit = async () => {
    if (
      props.formData.containerId?.length > 50 ||
      props.formData.workflowState?.length > 100 ||
      props.formData.buowSubject?.length > 50 ||
      props?.formData?.kpi1 > 100 ||
      props?.formData?.kpi2 > 100 ||
      props?.formData?.kpi3 > 100 ||
      props?.formData?.kpi4 > 100 ||
      props.formData.additionalMetadata > 500
    ) {
      return;
    }
    let payload = _.cloneDeep(props.formData);

    console.log(payload);
    props.isLoading(true);
    await updateBuow(payload).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('BUOW updated successfully');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchbuowData();
        props.setRecordModalShow(false);
        props.isLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: booleanValue,
    }));
  };

  return (
    <Modal
      {...props}
      className={[styles.buowTableModal, 'glbBuowModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size=""
      centered
      onHide={() => {
        props.onHide();
      }}
      data-testid="edit-buow-modal"
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>Edit BUOW</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.buowModalBody}>
          <div className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Context Id </label>
                <input
                  className={styles.disabledInput}
                  disabled={true}
                  name="contextId"
                  value={props?.formData?.contextId}
                  type="text"

                  // onChange={onChangeContextId}
                />
              </div>

              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>BUOW Type</label>
                <input className={styles.disabledInput} disabled={true} name="buowType" value={props.formData.buowType} type="text" onChange={handleInputChange} />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>BUOW Name </label>
                <input className={styles.disabledInput} name="buowName" value={props?.formData?.buowName} type="text" disabled={true} onChange={handleInputChange} />
              </div>

              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>BUOW Create DTTM</label>
                <input
                  className={styles.disabledInput}
                  disabled={true}
                  name="buowCreateDttm"
                  value={moment(props.formData.buowCreateDttm).format('M-DD-YYYY h:mm:ss A')}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Workflow State</label>
                <input name="workflowState" value={props.formData.workflowState} type="text" onChange={handleInputChange} />
                {props.formData.workflowState?.length > 100 && handleMaxLimitError(100)}
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>BUOW Subject</label>
                <input name="buowSubject" value={props?.formData?.buowSubject} type="text" onChange={handleInputChange} />
                {props.formData.buowSubject?.length > 50 && handleMaxLimitError(50)}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Container Id</label>
                <input name="containerId" value={props.formData.containerId} type="text" onChange={handleInputChange} />
                {props.formData.containerId?.length > 50 && handleMaxLimitError(50)}
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>KPI 1</label>
                <input name="kpi1" value={props?.formData?.kpi1} type="number" min="0" max="100" onChange={handleInputChange} onKeyDown={(e) => handleAllowPositiveNumbers(e)} />
                {props.formData.kpi1?.length > 100 && handleMaxLimitError(100)}
                {props?.formData?.kpi1 > 100 && handleCustomErrorMsg('Enter number between 0 to 100')}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>KPI 2</label>
                <input name="kpi2" value={props.formData.kpi2} type="number" min="0" max="100" onChange={handleInputChange} onKeyDown={(e) => handleAllowPositiveNumbers(e)} />
                {props.formData.kpi2?.length > 100 && handleMaxLimitError(100)}
                {props?.formData?.kpi2 > 100 && handleCustomErrorMsg('Enter number between 0 to 100')}
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>KPI 3</label>
                <input name="kpi3" value={props?.formData?.kpi3} type="number" min="0" max="100" onChange={handleInputChange} onKeyDown={(e) => handleAllowPositiveNumbers(e)} />
                {props.formData.kpi3?.length > 100 && handleMaxLimitError(100)}
                {props?.formData?.kpi3 > 100 && handleCustomErrorMsg('Enter number between 0 to 100')}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>KPI 4</label>
                <input name="kpi4" value={props.formData.kpi4} type="number" min="0" max="100" onChange={handleInputChange} onKeyDown={(e) => handleAllowPositiveNumbers(e)} />
                {props.formData.kpi4?.length > 100 && handleMaxLimitError(100)}
                {props?.formData?.kpi4 > 100 && handleCustomErrorMsg('Enter number between 0 to 100')}
              </div>

              <div className={[styles.inputFieldInternalDiv, 'col-6 justify-content-center'].join(' ')}>
                <div className={[styles.radioInputOuterDiv, 'mt-1'].join(' ')} data-testid="radio-function">
                  <div>Active Ind: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} />
                    True
                    <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label style={{ marginLeft: '1.4rem' }}>Additional Metadata</label>
                <textarea
                  name="additionalMetadata"
                  value={props.formData?.additionalMetadata !== null ? props.formData.additionalMetadata : ''}
                  onChange={handleInputChange}
                  type="text"
                  style={{ marginLeft: '1.4rem' }}
                  disabled
                  className={styles.disabledInput}
                />
                {props?.formData?.additionalMetadata?.length > 500 && handleMaxLimitError(500)}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.buowTableFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />

            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
