import React, { useState, useEffect } from 'react';
import './PageView.module.scss';
import Moment from 'moment';
import AsyncSelect from 'react-select/async';

const PageView = (props) => {
  return (
    <>
      <div className="filesList">
        {console.log('filesDetailsList', props.filesDetailsList)}
        {props.filesDetailsList?.map((itm, fileIndex) => (
          <div className={props.selectedBatchId === itm.batchId && props.selectedFileindex === fileIndex ? 'selectedFileSection' : 'fileSection'}>
            <div
              className={props.selectedBatchId === itm.batchId && props.selectedFileindex === fileIndex ? 'selectedFileHeader' : 'fileHeader'}
              onClick={itm?.isNewFile ? () => {} : () => props.onSelectFileHeader(itm, fileIndex)}
              style={itm?.isNewFile ? { cursor: 'default' } : { cursor: 'pointer' }}
            >
              {itm?.isNewFile === true ? (
                <div>
                  <span>File: </span>
                  <input name="newFileName" type="text" value={itm.newFileName} onChange={(e) => props.handleInputChange(e, itm, fileIndex)} className={'fileInputName'} />
                </div>
              ) : (
                <div>File: {itm.fileName}</div>
              )}

              <div>{Moment.utc(itm.date).local().format('MM/DD/YY hh:mm:ss A')}</div>
              <div>
                <span>
                  {itm?.objects?.length} Object{itm?.objects?.length === 1 ? '' : 's'}
                </span>
                <span style={{ paddingLeft: '8px' }}>
                  {itm?.pageCount} Page{itm?.pageCount === 1 ? '' : 's'}
                </span>
                {/* {itm?.objects?.length} Page{itm?.objects?.length === 1 ? '' : 's'} */}
              </div>
            </div>

            {itm?.objects?.map((objData, objectIndex) => (
              <div style={{ display: 'flex', position: 'relative', height: '250px' }} className="p-2">
                <div
                  className={
                    props.selectedObjectSectionId === objData?.FileId && props.selectedObjectindex === objectIndex && props.selectedFileindex === fileIndex
                      ? 'selectedObjectNameStyle'
                      : 'objectNameStyle'
                  }
                  style={objData?.isNewObject || itm?.isNewFile ? { cursor: 'default' } : { cursor: 'pointer' }}
                >
                  <div
                    className="arrowStyle"
                    onClick={() => {
                      props.onSelectObjType(objData?.FileId, objectIndex, fileIndex);
                    }}
                  >
                    {'>'}
                  </div>
                  <div
                    className="objectName"
                    title={objData?.DocTypeName}
                    onClick={objData?.isNewObject || itm?.isNewFile ? () => {} : () => props.onSelectObject(objData, itm, fileIndex, objectIndex)}
                  >
                    {objData?.DocTypeName}
                  </div>
                  {/* <div className="dropdownIcon">V</div> */}
                </div>

                {/* props.selectedObject === objData?.FileId && && props.showObjectTypes */}
                {props.dropdownObjectIndex === objectIndex && props.dropdownFileIndex === fileIndex && (
                  <div className="objectTypesList" ref={props.outsideClickRef} data-testid="pageViewSelect">
                    {props.documentTypes !== undefined && props.documentTypes.length > 0 && (
                      <AsyncSelect
                        defaultOptions
                        data-testid="asyncSelectDropdown"
                        value={Object.keys(objData).length !== 0 && objData.DocTypeName && { label: objData.DocTypeName, value: objData.DocTypeName }}
                        loadOptions={(inputValue, callback) => {
                          if (inputValue === '') {
                            let options = [];
                            props.documentTypes.map((res) => {
                              options.push({ label: res.objectTypeName, value: res.objectTypeUuid });
                              setTimeout(() => {
                                callback(options);
                              }, 1000);
                            });
                            callback(options);
                          } else {
                            let options = [];
                            props.documentTypes
                              .filter((i) => i.objectTypeName?.toLowerCase().includes(inputValue?.toLowerCase()))
                              .map((res) => {
                                options.push({ label: res.objectTypeName, value: res.objectTypeName });
                                setTimeout(() => {
                                  callback(options);
                                }, 1000);
                              });
                            callback(options);
                          }
                        }}
                        onChange={(e) => (objData?.isNewObject === true ? props.onchangeDocTypePageView(e) : props.onChangeDocType(e, objData, props.uploadedFiles))}
                        style={props.customStyles}
                      />
                    )}
                  </div>
                )}

                <div
                  className={
                    props.selectedObjectSectionId === objData?.FileId && props.selectedObjectindex === objectIndex && props.selectedFileindex === fileIndex
                      ? `selectedObjectImgStyle`
                      : `imageOuterDiv`
                  }
                >
                  {objData?.ObjectDetails?.map((thumbObj) => (
                    // className={[(props.selectedPageNumber === thumbObj?.PAGE_NO && props.selectedThumbnailFile === objData?.FileId && itm?.isNewFile === false) ? thumbObj.rotationVal === 90 || thumbObj.rotationVal === 270 ? "rotatedImage" : "selectedThumbnail" : "pageThumbnail"].join(' ')}
                    <img
                      className={[
                        thumbObj?.selectedPage === true && itm?.isNewFile === false
                          ? thumbObj.rotationVal === 90 || thumbObj.rotationVal === 270
                            ? 'rotatedImage'
                            : 'selectedThumbnail'
                          : 'pageThumbnail',
                      ].join(' ')}
                      src={'data:image/gif;base64,' + thumbObj?.OBJECT_PAGE_THUMBNAIL}
                      onClick={objData?.isNewObject || itm?.isNewFile ? () => {} : () => props.onSelectPage(thumbObj, objData)}
                      alt="Thumbnail"
                      style={objData?.isNewObject || itm?.isNewFile ? { cursor: 'default' } : { cursor: 'pointer', transform: `rotate(${thumbObj.rotationVal}deg)` }}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default PageView;
