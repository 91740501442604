import React, { useEffect, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './BatchDefinition.module.scss';
import '../../../GlobalStyle.scss';
import AsyncSelect from 'react-select/async';
// import TableBatchDefinition from './TableBatchDefinition';
import BatchDefinitionData from './BatchDefinitionData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { saveBatchDefinition, deleteBatchDefinition, searchBatchDefinition, updateBatchDefinition } from './BatchDefinitionTableService';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import { handleCustomErrorMsg, handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';
import { emailValidator } from '../../../components/FormModules/validators.js';
import { getDefaultWorkGroupDisplayName, getWorkGroupDisplayName } from '../WorkGroupUserXWalk/WorkGroupUserXWalkService.js';
import moment from 'moment';
// import { searchBatchDefinition } from '../RoutingSteps/RoutingStepsServices';

const TableBatchDefinition = lazy(() => import('./BatchDefinitionTable.js'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
// const { DeleteModal } = lazy(() => import('../../../newcomponents/DeletePopup/DeleteModal'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));

var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Batch Definition', link: '' },
];

const integrationModeData = ['S', 'I', 'C'];

export default function BatchDefinition() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    contextId: '',
    batchId: '',
    type: '',
    name: '',
    systemOfRecord: '',
    integrationMode: '',
    integrationPath: '',
    passQualityCalculation: '',
    description: '',
    requestType: '',
    sorReference: '',
    validationType: '',
    driver: '',
    sampleSizePercent: '',
    sampleSizeNumber: '',
    sampleSelection: '',
    passQualityLevel: '',
    output: '',
    responsibleWorkgroup: '',
    emailTargets: [],
    autoprocess: false,
    autoInitiateBuow: false,
    crosswalkEntitySelectionLabel: '',
    selectionLabel: '',
    buowTotal: '',
    buowComplete: '',
    buowAchievingTargetComplete: '',
    startDate: '',
    dueDate: '',
    packagerMode: '',
    lastPackagerRun: '',
    packagerComplete: false,
    completeDate: '',
    activeInd: true,
    workgroupDisplayName: '',
    additionalMetadata: '',
  });
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [BatchDefinitionDetails, setBatchDefinitionData] = useState(BatchDefinitionData);
  const [inputDisabled, setInputDisabled] = useState(false);
  const userContextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

  async function fetchBatchDefinitionData() {
    let payload = {
      contextId: userContextId,
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchBatchDefinition(payload).then((response) => {
      response?.data && setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchBatchDefinitionData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      contextId: userContextId,
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchBatchDefinition(payload).then((response) => {
      response?.data && setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      contextId: '',
      batchId: '',
      type: '',
      name: '',
      systemOfRecord: '',
      integrationMode: '',
      integrationPath: '',
      passQualityCalculation: '',
      description: '',
      requestType: '',
      sorReference: '',
      validationType: '',
      driver: '',
      sampleSizePercent: '',
      sampleSizeNumber: '',
      sampleSelection: '',
      passQualityLevel: '',
      output: '',
      responsibleWorkgroup: '',
      emailTargets: [],
      autoprocess: false,
      autoInitiateBuow: false,
      crosswalkEntitySelectionLabel: '',
      selectionLabel: '',
      buowTotal: '',
      buowComplete: '',
      buowAchievingTargetComplete: '',
      startDate: '',
      dueDate: '',
      packagerMode: '',
      lastPackagerRun: '',
      packagerComplete: false,
      completeDate: '',
      activeInd: true,
      workgroupDisplayName: '',
      additionalMetadata: '',
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      contextId: DeleteData.contextId,
      batchId: DeleteData.batchId,
    };

    deleteBatchDefinition(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchBatchDefinitionData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Batch Definition Deleted Successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  const editFunc = (data) => {
    setFormData({
      contextId: data.contextId,
      batchId: data.batchId,
      type: data.type,
      name: data.name,
      systemOfRecord: data.systemOfRecord,
      integrationMode: data.integrationMode,
      integrationPath: data.integrationPath,
      passQualityCalculation: data.passQualityCalculation,
      description: data.description,
      requestType: data.requestType,
      sorReference: data.sorReference,
      validationType: data.validationType,
      driver: data.driver,
      sampleSizePercent: data.sampleSizePercent,
      sampleSizeNumber: data.sampleSizeNumber,
      sampleSelection: data.sampleSelection,
      passQualityLevel: data.passQualityLevel,
      output: data.output,
      responsibleWorkgroup: data.responsibleWorkgroup,
      emailTargets: data.emailTargets,
      autoprocess: data.autoprocess === null || data.autoprocess === undefined ? false : data.autoprocess,
      autoInitiateBuow: data.autoInitiateBuow === null || data.autoInitiateBuow === undefined ? false : data.autoInitiateBuow,
      crosswalkEntitySelectionLabel: data.crosswalkEntitySelectionLabel,
      selectionLabel: data.selectionLabel,
      buowTotal: data.buowTotal,
      buowComplete: data.buowComplete,
      buowAchievingTargetComplete: data.buowAchievingTargetComplete,
      startDate: moment(data.startDate).format('YYYY-MM-DD'),
      dueDate: moment(data.dueDate).format('YYYY-MM-DD'),
      packagerMode: data.packagerMode,
      lastPackagerRun: moment(data.lastPackagerRun).format('YYYY-MM-DD'),
      packagerComplete: data.packagerComplete === null || data.packagerComplete === undefined ? false : data.packagerComplete,
      completeDate: moment(data.completeDate).format('YYYY-MM-DD'),
      activeInd: data.activeInd === null || data.activeInd === undefined ? true : data.activeInd,
      workgroupDisplayName: data.workgroupDisplayName,
      additionalMetadata: data.additionalMetadata,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) onSearch(searchText);
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      contextId: userContextId,
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchBatchDefinition(payload).then((response) => {
      response?.data && setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  // const onSearch = async (searchText) => {
  // setLoading(true);
  // let payload = {
  // text: searchText,
  // column: sorting.column,
  // order: sorting.order,
  // };

  // await searchBatchDefinition(payload).then((response) => {
  // setData(response.data);
  // setPage(0);
  // setLoading(false);
  // });
  // };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newBatchDefinitionData = JSON.parse(JSON.stringify(BatchDefinitionDetails));
    newBatchDefinitionData.forEach((itm) => {
      itm.sortType = '';
    });
    setBatchDefinitionData(newBatchDefinitionData);
    await fetchNewBatchDefinitionData();
  };

  // Code needs to change
  const fetchNewBatchDefinitionData = async () => {
    let payload = {
      contextId: userContextId,
      text: '',
      column: '',
      order: '',
    };
    await searchBatchDefinition(payload).then((response) => {
      response?.data && setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      contextId: userContextId,
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchBatchDefinition(payload).then((response) => {
      response?.data && setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.batchDefinitionPage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.batchDefinitionPageContent}>
        <h1 className={styles.pageTitle}>Batch Definition</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create Batch Definition" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.batchDefinitionTable, 'glbBatchDefinitionTable'].join(' ')}>
          <TableBatchDefinition
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={BatchDefinitionDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <CreateEditBatchDefinition
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchBatchDefinitionData={fetchBatchDefinitionData}
          userContextId={userContextId}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle={'Batch Definition'}
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functionality
const CreateEditBatchDefinition = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  const [requestTypeList, setRequestTypeList] = useState(['Congressional', 'FOIA', '4506', 'Other']);
  const [validationTypeList, setValidationTypeList] = useState(['None', 'StatSample', 'Criteria']);
  const [sampleSelectionList, setSampleSelectionList] = useState(['Random', 'FIFO', 'Other']);
  const [OutputList, setOutputList] = useState(['Email', 'Package', 'Other']);
  const [packagerModeList, setPackagerModeList] = useState(['One Time', 'Continuous']);
  const [emailValidationError, setEmailValidationError] = useState();
  const [jsonError, setJsonError] = useState();

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      // position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const loadIntegrationModeData = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let options = [];
      integrationModeData.map((res) => {
        options.push({ label: res, value: res });
        setTimeout(() => {
          callback(options);
        }, 1000);
      });
      callback(options);
    } else {
      let options = await integrationModeData.map((res) => {
        return { label: res, value: res };
      });
      callback(options);
    }
  };

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const onChangeWorkGroupDisplayName = (selectedOptions) => {
    if (!selectedOptions) {
      selectedOptions = [];
    }

    props.setFormData((prevData) => ({
      ...prevData,
      responsibleWorkgroup: selectedOptions.value,
      workgroupDisplayName: selectedOptions.label,
    }));
  };
  const onChangeDropdownValue = (event, name) => {
    if (event == null) {
      if (name === 'validationType') {
        props.setFormData((prevData) => ({
          ...prevData,
          sampleSizePercent: '',
          sampleSizeNumber: '',
        }));
      }
      if (name === 'output') {
        props.setFormData((prevData) => ({
          ...prevData,
          emailTargets: [],
        }));
        setEmailValidationError('');
      }

      return props.setFormData((prevData) => ({
        ...prevData,
        [name]: '',
      }));
    }
    const { value } = event;

    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));

    if (name === 'packagerMode' && value === 'One Time') {
      props.setFormData((prevData) => ({
        ...prevData,
        packagerComplete: true,
      }));
    }
    if (name === 'validationType' && value !== 'StatSample') {
      props.setFormData((prevData) => ({
        ...prevData,
        sampleSizePercent: '',
        sampleSizeNumber: '',
      }));
    }
    if (name === 'output' && value !== 'Email') {
      props.setFormData((prevData) => ({
        ...prevData,
        emailTargets: [],
      }));
      setEmailValidationError('');
    }
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      await searchBatchDefinition(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });

        callback(options);
      });
    }
  };

  const loadDropdownOptions = async (inputValue, field, callback) => {
    if (inputValue === '') {
    } else {
      await searchBatchDefinition(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });

        callback(options);
      });
    }
  };
  const loadWorkGroupDisplayName = async (inputValue, callback) => {
    if (inputValue === '') {
      // callback([]);
      await getDefaultWorkGroupDisplayName(inputValue).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          options.push({ label: res.workgroupDisplayName, value: res.workgroupId });
          setTimeout(() => {
            callback(options);
          }, 1000);
        });
        callback(options);
      });
    } else {
      await getWorkGroupDisplayName(inputValue).then(async (response) => {
        let options = await response.data.map((res) => {
          return { label: res.workgroupDisplayName, value: res.workgroupId };
        });

        callback(options);
      });
    }
  };

  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'emailTargets') {
      setEmailValidationError('');
      let emailArray = value?.split(',')?.map((email) => email.trim());

      props.setFormData((prevData) => ({
        ...prevData,
        emailTargets: emailArray,
      }));

      return;
    }
    if (name === 'additionalMetadata') {
      setJsonError();
    }

    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value.trimStart(),
    }));
  };
  const handleRadioChange = (event) => {
    const value = event.target.value;
    let name = event.target.name;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: booleanValue,
    }));
  };

  const onChangeIntegrationMode = (event) => {
    if (event == null) {
      return props.setFormData((prevData) => ({
        ...prevData,
        integrationMode: '',
        integrationPath: '', // Reset integrationPath when integrationMode is cleared
      }));
    }

    const { value } = event;
    const updatedValue = value === '' ? null : value;

    props.setFormData((prevData) => ({
      ...prevData,
      integrationMode: updatedValue,

      // Reset integrationPath if integrationMode is set to 'S'
      integrationPath: updatedValue === 'S' ? '' : prevData.integrationPath,
    }));
  };
  const isValidJson = (str) => {
    if (str !== null && str !== undefined && str !== '') {
      try {
        const parsed = JSON.parse(str);
        if (typeof parsed === 'object' && Object.keys(parsed).length === 0) {
          return true; // It is an empty object
        }

        if (!isNaN(parsed) && !isNaN(parseFloat(parsed))) {
          return true; // It is a number
        }

        return false;
      } catch (e) {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    try {
      const emailTargets = props.formData.emailTargets || [];

      const errors = emailTargets
        .filter((value) => value !== '')
        .map((itm) => emailValidator(itm))
        .find((error) => error !== '');
      setEmailValidationError(errors);
      //validate if additional metadata has correct json
      const isValidData = isValidJson(props.formData.additionalMetadata);
      if (isValidJson(props.formData.additionalMetadata) === true) {
        setJsonError('Invalid JSON format.');
      } else {
        setJsonError();
      }
      if (
        // !props.formData.contextId ||
        // props.formData.contextId?.length > 50 ||
        !props.formData.name ||
        !props.formData.batchId ||
        props.formData.name?.length > 200 ||
        props.formData.crosswalkEntitySelectionLabel?.length > 200 ||
        props.formData.integrationPath?.length > 200 ||
        props.formData.passQualityCalculation?.length > 1000 ||
        props.formData.description?.length > 500 ||
        props.formData.driver?.length > 50 ||
        errors ||
        isValidData
      ) {
        setisRequiredError(true);
        return;
      }
      setisRequiredError(false);
      let payload = _.cloneDeep(props.formData);

      payload.startDate = moment(payload.startDate).isValid() ? moment(payload.startDate).format('yyyy-MM-DDTHH:mm:ss') : '';
      payload.dueDate = moment(payload.dueDate).isValid() ? moment(payload.dueDate).format('yyyy-MM-DDTHH:mm:ss') : '';
      payload.completeDate = moment(payload.completeDate).isValid() ? moment(payload.completeDate).format('yyyy-MM-DDTHH:mm:ss') : '';
      payload.lastPackagerRun = moment(payload.lastPackagerRun).isValid() ? moment(payload.lastPackagerRun).format('yyyy-MM-DDTHH:mm:ss') : '';

      payload.contextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;
      props.isLoading(true);
      await saveBatchDefinition(payload)
        .then((response) => {
          if (response.data) {
            props.fetchBatchDefinitionData();
            props.setRecordModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('Batch Definition Created Successfully.');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleEdit = async () => {
    const emailTargets = props.formData.emailTargets || [];

    const errors = emailTargets
      ?.filter((value) => value !== '')
      ?.map((itm) => emailValidator(itm))
      ?.find((error) => error !== '');
    setEmailValidationError(errors);

    //validate if additional metadata has correct json

    const isValidData = isValidJson(props.formData.additionalMetadata);
    if (isValidJson(props.formData.additionalMetadata) === true) {
      setJsonError('Invalid JSON format.');
    } else {
      setJsonError();
    }

    if (
      !props.formData.contextId ||
      // props.formData.contextId?.length > 50 ||
      !props.formData.name ||
      !props.formData.batchId ||
      props.formData.name?.length > 200 ||
      props.formData.crosswalkEntitySelectionLabel?.length > 200 ||
      props.formData.integrationPath?.length > 200 ||
      props.formData.passQualityCalculation?.length > 1000 ||
      props.formData.description?.length > 500 ||
      props.formData.driver?.length > 50 ||
      errors ||
      isValidData
    ) {
      setisRequiredError(true);
      return;
    }
    setisRequiredError(false);

    let payload = _.cloneDeep(props.formData);
    payload.startDate = moment(payload.startDate).isValid() ? moment(payload.startDate).format('yyyy-MM-DDTHH:mm:ss') : '';
    payload.dueDate = moment(payload.dueDate).isValid() ? moment(payload.dueDate).format('yyyy-MM-DDTHH:mm:ss') : '';
    payload.completeDate = moment(payload.completeDate).isValid() ? moment(payload.completeDate).format('yyyy-MM-DDTHH:mm:ss') : '';
    payload.lastPackagerRun = moment(payload.lastPackagerRun).isValid() ? moment(payload.lastPackagerRun).format('yyyy-MM-DDTHH:mm:ss') : '';

    props.isLoading(true);

    await updateBatchDefinition(payload).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('Batch Definition Updated Successfully.');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchBatchDefinitionData();
        props.setRecordModalShow(false);

        props.isLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };

  return (
    <Modal
      {...props}
      className={[styles.batchDefinitionModal, 'glbBatchDefinitionModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={() => {
        props.onHide();
        setJsonError();
      }}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Batch Definition' : 'Edit Batch Definition'}</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
                setEmailValidationError('');
                setJsonError();
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.batchDefinitionModalBody}>
          <div className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              {props.isNew ? (
                <></>
              ) : (
                <div className={styles.inputFieldInternalDiv}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                    onChange={onChangeContextId}
                    className={props.isNew ? '' : styles.disabledInput}
                  />
                </div>
              )}
              <div className={styles.inputFieldInternalDiv}>
                <label>Batch Id *</label>
                <input
                  required
                  name="batchId"
                  type="number"
                  value={props.formData.batchId}
                  onChange={handleInputChange}
                  disabled={props.isNew ? false : true}
                  onKeyDown={(e) => ['e', 'E'].includes(e.key) && e.preventDefault()}
                  className={props.isNew ? '' : styles.disabledInput}
                />
                {(props.formData.batchId === null || props.formData.batchId === '') && isRequiredError === true && handleIsRequiredError()}
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Name *</label>
                <input required name="name" value={props.formData.name} onChange={handleInputChange} />
                {(props.formData.name === null || props.formData.name === '') && isRequiredError === true && handleIsRequiredError()}
                {props.formData.name?.length > 200 && handleMaxLimitError(200)}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>SOR Reference </label>
                <input name="sorReference" value={props.formData.sorReference} onChange={handleInputChange} />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>System of Record </label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  // loadOptions={loadDropdownOptions}
                  value={props?.formData?.systemOfRecord !== '' ? { label: props?.formData?.systemOfRecord, value: props?.formData?.systemOfRecord } : null}
                  onChange={(event) => onChangeDropdownValue(event, 'systemOfRecord')}
                />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Request Type </label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={(inputValue, callback) => {
                    if (inputValue === '') {
                      callback([]);
                      let options = [];
                      requestTypeList.map((res) => {
                        options.push({ label: res, value: res });
                        setTimeout(() => {
                          callback(options);
                        }, 1000);
                      });
                      callback(options);
                    } else {
                      let options = requestTypeList.map((res) => {
                        return { label: res, value: res };
                      });
                      callback(options);
                    }
                  }}
                  value={props?.formData?.requestType !== '' ? { label: props?.formData?.requestType, value: props?.formData?.requestType } : null}
                  onChange={(event) => onChangeDropdownValue(event, 'requestType')}
                  defaultOptions
                />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Validate Type </label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={(inputValue, callback) => {
                    if (inputValue === '') {
                      callback([]);
                      let options = [];
                      validationTypeList.map((res) => {
                        options.push({ label: res, value: res });
                        setTimeout(() => {
                          callback(options);
                        }, 1000);
                      });
                      callback(options);
                    } else {
                      let options = validationTypeList.map((res) => {
                        return { label: res, value: res };
                      });
                      callback(options);
                    }
                  }}
                  value={props?.formData?.validationType !== '' ? { label: props?.formData?.validationType, value: props?.formData?.validationType } : null}
                  onChange={(event) => onChangeDropdownValue(event, 'validationType')}
                  defaultOptions
                />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Sample Size % </label>
                <input
                  name="sampleSizePercent"
                  value={props.formData.sampleSizePercent}
                  onChange={handleInputChange}
                  type="number"
                  onKeyDown={(e) => ['e', 'E'].includes(e.key) && e.preventDefault()}
                  disabled={props?.formData?.validationType !== 'StatSample'}
                  className={props?.formData?.validationType !== 'StatSample' ? styles.disabledInput : ''}
                />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.radioInputOuterDiv} data-testid="radio-function">
                  <div>Auto Process: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="autoprocess" value={true} checked={props.formData.autoprocess === true} onChange={handleRadioChange} />
                    True
                    <input type="radio" name="autoprocess" value={false} checked={props.formData.autoprocess === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Sample Size #</label>
                <input
                  name="sampleSizeNumber"
                  value={props.formData.sampleSizeNumber}
                  onChange={handleInputChange}
                  type="number"
                  onKeyDown={(e) => ['e', 'E'].includes(e.key) && e.preventDefault()}
                  disabled={props?.formData?.validationType !== 'StatSample'}
                  className={props?.formData?.validationType !== 'StatSample' ? styles.disabledInput : ''}
                />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Sample Selection </label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={(inputValue, callback) => {
                    if (inputValue === '') {
                      callback([]);
                      let options = [];
                      sampleSelectionList.map((res) => {
                        options.push({ label: res, value: res });
                        setTimeout(() => {
                          callback(options);
                        }, 1000);
                      });
                      callback(options);
                    } else {
                      let options = sampleSelectionList.map((res) => {
                        return { label: res, value: res };
                      });
                      callback(options);
                    }
                  }}
                  value={props?.formData?.sampleSelection !== '' ? { label: props?.formData?.sampleSelection, value: props?.formData?.sampleSelection } : null}
                  onChange={(event) => onChangeDropdownValue(event, 'sampleSelection')}
                  defaultOptions
                />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Pass Quality Level</label>
                <input
                  name="passQualityLevel"
                  value={props.formData.passQualityLevel}
                  onChange={handleInputChange}
                  type="number"
                  onKeyDown={(e) => ['e', 'E'].includes(e.key) && e.preventDefault()}
                />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Output </label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={(inputValue, callback) => {
                    if (inputValue === '') {
                      callback([]);
                      let options = [];
                      OutputList.map((res) => {
                        options.push({ label: res, value: res });
                        setTimeout(() => {
                          callback(options);
                        }, 1000);
                      });
                      callback(options);
                    } else {
                      let options = OutputList.map((res) => {
                        return { label: res, value: res };
                      });
                      callback(options);
                    }
                  }}
                  defaultValue={props?.formData?.output !== '' ? { label: props?.formData?.output, value: props?.formData?.output } : null}
                  onChange={(event) => onChangeDropdownValue(event, 'output')}
                  defaultOptions
                />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Responsible Workgroup </label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  defaultOptions
                  loadOptions={loadWorkGroupDisplayName}
                  value={
                    props?.formData?.responsibleWorkgroup !== ''
                      ? { label: props?.formData?.workgroupDisplayName, value: props?.formData?.responsibleWorkgroup }
                      : { label: '---Select---' }
                  }
                  onChange={onChangeWorkGroupDisplayName}
                />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Email Targets </label>
                <input
                  name="emailTargets"
                  value={props.formData.emailTargets}
                  onChange={handleInputChange}
                  title={props.formData.emailTargets}
                  disabled={props?.formData?.output !== 'Email'}
                  className={props?.formData?.output !== 'Email' ? styles.disabledInput : ''}
                />
                {(props.formData.emailTargets !== null || props.formData.emailTargets !== undefined) && <span style={{ color: 'red' }}>{emailValidationError}</span>}
              </div>
            </div>
            {!props.isNew && (
              <div className={styles.inputFieldSection}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>BUOW Total</label>
                  <input
                    disabled
                    name="buowTotal"
                    value={props.formData.buowTotal}
                    onChange={handleInputChange}
                    type="number"
                    onKeyDown={(e) => ['e', 'E'].includes(e.key) && e.preventDefault()}
                    className={!props.isNew ? styles.disabledInput : ''}
                  />
                </div>

                <div className={styles.inputFieldInternalDiv}>
                  <label>BUOW Complete</label>
                  <input
                    disabled
                    name="buowComplete"
                    value={props.formData.buowComplete}
                    onChange={handleInputChange}
                    type="number"
                    onKeyDown={(e) => ['e', 'E'].includes(e.key) && e.preventDefault()}
                    className={!props.isNew ? styles.disabledInput : ''}
                  />
                </div>

                <div className={styles.inputFieldInternalDiv}>
                  <label>BUOW Achieving Target Complete</label>
                  <input
                    disabled
                    name="buowAchievingTargetComplete"
                    value={props.formData.buowAchievingTargetComplete}
                    onChange={handleInputChange}
                    type="number"
                    onKeyDown={(e) => ['e', 'E'].includes(e.key) && e.preventDefault()}
                    className={!props.isNew ? styles.disabledInput : ''}
                  />
                </div>
              </div>
            )}

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Crosswalk Entity Selection Label</label>
                <input name="crosswalkEntitySelectionLabel" value={props.formData.crosswalkEntitySelectionLabel} onChange={handleInputChange} />
                {props.formData.crosswalkEntitySelectionLabel?.length > 200 && handleMaxLimitError(200)}
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Packager Mode </label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={(inputValue, callback) => {
                    if (inputValue === '') {
                      callback([]);
                      let options = [];
                      packagerModeList.map((res) => {
                        options.push({ label: res, value: res });
                        setTimeout(() => {
                          callback(options);
                        }, 1000);
                      });
                      callback(options);
                    } else {
                      let options = packagerModeList.map((res) => {
                        return { label: res, value: res };
                      });
                      callback(options);
                    }
                  }}
                  value={props?.formData?.packagerMode !== '' ? { label: props?.formData?.packagerMode, value: props?.formData?.packagerMode } : null}
                  onChange={(event) => onChangeDropdownValue(event, 'packagerMode')}
                  defaultOptions
                />
              </div>
              {!props.isNew && (
                <div className={styles.inputFieldInternalDiv}>
                  <label>Last Packager Run</label>
                  <input name="lastPackagerRun" value={props.formData.lastPackagerRun} onChange={handleInputChange} type="date" disabled={true} />
                </div>
              )}
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Start Date</label>
                <input name="startDate" value={props.formData.startDate} onChange={handleInputChange} type="date" />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Due Date</label>
                <input name="dueDate" value={props.formData.dueDate} onChange={handleInputChange} type="date" />
              </div>

              <div className={[styles.inputFieldInternalDiv, 'col-4'].join(' ')}>
                <div className={styles.radioInputOuterDiv} data-testid="radio-function">
                  <div>Packager Complete: </div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type="radio"
                      name="packagerComplete"
                      value={true}
                      checked={props.formData.packagerComplete === true}
                      onChange={handleRadioChange}
                      // disabled={props?.formData?.packagerMode === 'One Time'}
                    />
                    True
                    <input
                      type="radio"
                      name="packagerComplete"
                      value={false}
                      checked={props.formData.packagerComplete === false}
                      onChange={handleRadioChange}
                      // disabled={props?.formData?.packagerMode === 'One Time'}
                    />{' '}
                    False
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Driver</label>
                <input name="driver" value={props.formData.driver} onChange={handleInputChange} />
                {props.formData.driver?.length > 50 && handleMaxLimitError(50)}
              </div>

              <div className={[styles.inputFieldInternalDiv, 'col-4'].join(' ')}>
                <div className={styles.radioInputOuterDiv} data-testid="radio-function">
                  <div>Active Ind: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} />
                    True
                    <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-4'].join(' ')}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Complete Date</label>
                  <input name="completeDate" value={props.formData.completeDate} onChange={handleInputChange} type="date" />
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Integration Mode</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadIntegrationModeData}
                  value={props?.formData?.integrationMode !== '' ? { label: props?.formData?.integrationMode, value: props?.formData?.integrationMode } : null}
                  onChange={onChangeIntegrationMode}
                  isSearchable={false}
                  defaultOptions
                />
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Integration Path</label>
                <input
                  name="integrationPath"
                  value={props.formData.integrationPath}
                  onChange={handleInputChange}
                  disabled={props?.formData?.integrationMode !== 'I' && props?.formData?.integrationMode !== 'C'}
                  className={props?.formData?.integrationMode !== 'I' && props?.formData?.integrationMode !== 'C' ? styles.disabledInput : ''}
                />
                {props.formData.integrationPath?.length > 200 && handleMaxLimitError(200)}
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.radioInputOuterDiv} data-testid="radio-function">
                  <div>Auto Initiate BUOW</div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="autoInitiateBuow" value={true} checked={props.formData.autoInitiateBuow === true} onChange={handleRadioChange} />
                    True
                    <input type="radio" name="autoInitiateBuow" value={false} checked={props.formData.autoInitiateBuow === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Pass Quality Calculation</label>
                <textarea required name="passQualityCalculation" value={props.formData.passQualityCalculation} onChange={handleInputChange} />
                {props.formData.passQualityCalculation?.length > 1000 && handleMaxLimitError(1000)}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Description</label>
                <textarea required name="description" value={props.formData.description} onChange={handleInputChange} />
                {props.formData.description?.length > 500 && handleMaxLimitError(500)}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Additional Metadata</label>
                <textarea required name="additionalMetadata" value={props.formData.additionalMetadata} onChange={handleInputChange} />
                {jsonError !== undefined && handleCustomErrorMsg(jsonError)}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.orchestrationFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" parentWarpper={'d-flex align-items-center'} className={styles.saveRecordBtn} onClick={handleSubmit} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
            )}
            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
                setEmailValidationError('');
                setJsonError();
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
