import React, { useEffect, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './RuleDefinition.module.scss';
import '../../../GlobalStyle.scss';

// import TableRuleDefinition from './TableRuleDefinition';
import RuleDefinitionData from './RuleDefinitionData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { createRuleDefinition, deleteRuleDefinition, searchRuleDefinition, updateRuleDefinition } from './RuleDefinitionServices';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import AsyncSelect from 'react-select/async';
import { handleMaxLimitError, handleIsRequiredError } from '../../../utils/helper/handleReuiredErrorFunc';
import { getSearchContextId } from '../RoutingSteps/RoutingStepsServices';

const userInfoContext = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

// var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Rule Definition', link: '' },
];

const TableRuleDefinition = lazy(() => import('./TableRuleDefinition'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));

export default function RuleDefinition() {
  // const [stepError, setStepError] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [ruleDefinitionDetails, setRuleDefinitionData] = useState(RuleDefinitionData);
  const [inputDisabled, setInputDisabled] = useState(false);

  const [formData, setFormData] = useState({
    ruleDisplayName: '',
    ruleType: '',
    ruleLevel: '',
    ruleDescription: '',
    confidence: true,
    editValue: true,
    saveEditValue: true,
    clipboard: true,
    clipboardable: true,
    visual: true,
    activeInd: true,
    contextId: '',
    ruleId: '',
  });
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);

  async function fetchRuleDefinitionData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };
    await searchRuleDefinition(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchRuleDefinitionData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchRuleDefinition(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      ruleDisplayName: '',
      ruleType: '',
      ruleLevel: '',
      ruleDescription: '',
      confidence: true,
      editValue: true,
      saveEditValue: true,
      clipboard: true,
      clipboardable: true,
      visual: true,
      activeInd: true,
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      // ruleId: "ruleId091"
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      ruleId: DeleteData.ruleId,
    };

    deleteRuleDefinition(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchRuleDefinitionData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Rule Definition deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  const editFunc = (data) => {
    setFormData({
      ruleDisplayName: data.ruleDisplayName,
      ruleType: data.ruleType,
      ruleLevel: data.ruleLevel,
      ruleDescription: data.ruleDescription,
      confidence: data.confidence,
      editValue: data.editValue,
      saveEditValue: data.saveEditValue,
      clipboard: data.clipboard,
      clipboardable: data.clipboardable,
      visual: data.visual,
      contextId: data.contextId,
      ruleId: data.ruleId,
      activeInd: data.activeInd === null || data.activeInd === undefined ? true : data.activeInd,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  // const handleSearch = (e) => {
  //   const { value } = e.target;
  //   setSearchText(value.trimStart());
  //   if (value.length > 2) onSearch(value);
  //   if (value.length === 0) onSearch(value);
  // };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (value) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: value,
      column: sorting.column,
      order: sorting.order,
    };

    await searchRuleDefinition(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setLoading(false);
    // Clear sort from table header
    let newRuleDefinitionData = JSON.parse(JSON.stringify(ruleDefinitionDetails));
    newRuleDefinitionData.forEach((itm) => {
      itm.sortType = '';
    });
    setRuleDefinitionData(newRuleDefinitionData);
    await fetchNewRuleDefinitionData();
  };

  // Code needs to change
  const fetchNewRuleDefinitionData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchRuleDefinition(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchRuleDefinition(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div data-testid="rule-definition-container" className={styles.ruleDefinitionPage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div data-testid="rule-definition-header-container" className={styles.ruleDefinitionPageContent}>
        <h1 className={styles.pageTitle}>Rule Definition</h1>
        <div>
          <div data-testid="search-section-container" className={styles.searchSection}>
            <div data-testid="keyword-search" className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
              <div data-testid="create-btn-container">
                <CustomButton data-testid="create-btn" title="Create Rule Definition" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span data-testid="help-text-search" className="searchNoteStyle">
              {' '}
              Note: Entering 3 letters enables search capabilities.
            </span>
          </div>
        </div>
        <div data-testid="table-container" className={[styles.ruleDefinitionTable, 'glbRuleDefinitionTable'].join(' ')}>
          <TableRuleDefinition
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={ruleDefinitionDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <AddEditRuleDefinitionModal
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchRuleDefinitionData={fetchRuleDefinitionData}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle={'Rule Definition'}
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functioanlity
const AddEditRuleDefinitionModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  // const [searchDataBUOW, setSearchDataBUOW] = useState([]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  useEffect(() => {
    setisRequiredError(false);
  }, [props.formData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (
        !props.formData.contextId ||
        props.formData.contextId?.length > 50 ||
        !props.formData.ruleDisplayName ||
        props.formData.ruleDisplayName?.length > 100 ||
        props.formData.ruleType?.length > 50 ||
        props.formData.ruleLevel?.length > 50 ||
        props.formData.ruleDescription?.length > 100
      ) {
        setisRequiredError(true);
        return;
      }
      props.isLoading(true);
      await createRuleDefinition(props.formData)
        .then((response) => {
          if (response.data) {
            props.fetchRuleDefinitionData();
            props.setRecordModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('Rule Definition created successfully');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleEdit = async () => {
    if (
      !props.formData.contextId ||
      props.formData.contextId?.length > 50 ||
      !props.formData.ruleDisplayName ||
      props.formData.ruleDisplayName?.length > 100 ||
      props.formData.ruleType?.length > 50 ||
      props.formData.ruleLevel?.length > 50 ||
      props.formData.ruleDescription?.length > 100
    ) {
      setisRequiredError(true);
      return;
    }
    props.isLoading(true);
    await updateRuleDefinition(props.formData).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('Rule Definition updated successfully');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchRuleDefinitionData();
        props.setRecordModalShow(false);
        props.isLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: booleanValue,
    }));
  };

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
      // setSearchDataBUOW([]);
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });
        // setSearchDataBUOW(options);
        callback(options);
      });
    }
  };

  return (
    <Modal
      {...props}
      className={[styles.ruleDefinitionModal, 'glbRuleDefinitionModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div data-testid="create-modal">
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div data-testid="modal-header-container">
              <h2 data-testid="modal-header" className={styles.modalHeaderTitle}>
                {props.isNew ? 'Create Rule Definition' : 'Edit Rule Definition'}
              </h2>
            </div>
            <div
              data-testid="header-cross-icn-container"
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
              }}
            >
              <img data-testid="header-cross-icn" src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.ruleDefinitionModalBody}>
          <div className={styles.modalBodySection}>
            {!props.isNew && (
              <div className={styles.inputFieldSection}>
                <div className={[styles.inputFieldInternalDiv, 'col-12'].join(' ')}>
                  <label>Rule Id</label>
                  <input name="ruleId" value={props.formData.ruleId} onChange={handleInputChange} disabled={props.isNew ? false : true} />
                </div>
              </div>
            )}

            <div data-testid="context-id-container" className={styles.inputFieldSection}>
              {!props.isNew && (
                <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                    onChange={onChangeContextId}
                  />
                  {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                  {props.formData.contextId.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                </div>
              )}
              <div data-testid="confidence-container" className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <div className={styles.radioInputOuterDiv}>
                  <div>Confidence: </div>
                  <div style={{ display: 'flex' }}>
                    <input data-testid="radio-input-1" type="radio" name="confidence" value={true} checked={props.formData.confidence === true} onChange={handleRadioChange} /> True
                    <input
                      data-testid="radio-input-2"
                      type="radio"
                      name="confidence"
                      value={false}
                      checked={props.formData.confidence === false}
                      onChange={handleRadioChange}
                    />{' '}
                    False
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div data-testid="rule-display-name-container" className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Rule Display Name *</label>
                <input data-testid="rule-display-name-input" name="ruleDisplayName" value={props.formData.ruleDisplayName} onChange={handleInputChange} />
                {(props.formData.ruleDisplayName === null || props.formData.ruleDisplayName === '') && isRequiredError === true && handleIsRequiredError()}
                {props.formData.ruleDisplayName.length > 100 && isRequiredError === true && handleMaxLimitError(100)}
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <div data-testid="edit-value-container" className={styles.radioInputOuterDiv}>
                  <div>Edit Value: </div>
                  <div style={{ display: 'flex' }}>
                    <input data-testid="edit-value-input-1" type="radio" name="editValue" value={true} checked={props.formData.editValue === true} onChange={handleRadioChange} />{' '}
                    True
                    <input
                      data-testid="edit-value-input-2"
                      type="radio"
                      name="editValue"
                      value={false}
                      checked={props.formData.editValue === false}
                      onChange={handleRadioChange}
                    />{' '}
                    False
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div data-testid="rule-type-container" className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Rule Type</label>
                <select
                  data-testid="rule-type-input"
                  className={styles.dropdownStyle}
                  id="selectField"
                  name="ruleType"
                  value={props.formData.ruleType}
                  onChange={handleSelectChange}
                >
                  <option value={''} disabled>
                    ---Select Type--
                  </option>
                  <option value="Match">Match</option>
                  <option value="Extract">Extract</option>
                  <option value="Validate">Validate</option>
                  <option value="Redact">Redact</option>
                  <option value="Inspect">Inspect</option>
                  <option value="Restrict">Restrict</option>
                </select>
                {props.formData.ruleType.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <div data-testid="save-edit-value-container" className={styles.radioInputOuterDiv}>
                  <div>Save Edit Value: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="saveEditValue" value={true} checked={props.formData.saveEditValue === true} onChange={handleRadioChange} /> True
                    <input type="radio" name="saveEditValue" value={false} checked={props.formData.saveEditValue === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div data-testid="rule-level-container" className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Rule Level</label>
                <select className={styles.dropdownStyle} id="selectField" name="ruleLevel" value={props.formData.ruleLevel} onChange={handleSelectChange}>
                  <option value={''} disabled>
                    ---Select Level--
                  </option>
                  <option value="Mandatory">Mandatory</option>
                  <option value="Likely">Likely</option>
                  <option value="Possible">Possible</option>
                  <option value="Warning">Warning</option>
                  <option value="Informational">Informational</option>
                  <option value="Fatal">Fatal</option>
                </select>
                {props.formData.ruleLevel.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <div data-testid="clipboard-container" className={styles.radioInputOuterDiv}>
                  <div>Clipboard: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="clipboard" value={true} checked={props.formData.clipboard === true} onChange={handleRadioChange} /> True
                    <input type="radio" name="clipboard" value={false} checked={props.formData.clipboard === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div data-testid="rule-description-container" className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Rule Description</label>
                <input name="ruleDescription" value={props.formData.ruleDescription} onChange={handleInputChange} />
                {props.formData.ruleDescription.length > 100 && isRequiredError === true && handleMaxLimitError(100)}
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <div data-testid="visual-container" className={styles.radioInputOuterDiv}>
                  <div>Visual: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="visual" value={true} checked={props.formData.visual === true} onChange={handleRadioChange} /> True
                    <input type="radio" name="visual" value={false} checked={props.formData.visual === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <div data-testid="clipboard-able-container" className={styles.radioInputOuterDiv}>
                  <div>Clipboard Able: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="clipboardable" value={true} checked={props.formData.clipboardable === true} onChange={handleRadioChange} /> True
                    <input type="radio" name="clipboardable" value={false} checked={props.formData.clipboardable === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>

              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <div className={[styles.radioInputOuterDiv].join(' ')} data-testid="radio-function">
                  <div>Active Ind: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} />
                    True
                    <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.ruleDefinitionFooter}>
          <div className={styles.footerInternalDiv} style={{ gap: '3rem' }}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={handleSubmit} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
            )}
            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create Edit record modal with functioanlity
