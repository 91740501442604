import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './UpdateFileCodeModal.module.scss';
import '../../../../GlobalStyle.scss';
import crossIcon from '../../../../fsa-style/img/crossIcon.svg';
import ResizeIcon from '../../../../fsa-style/img/svgs/ResizeIcon.svg';
import CustomButton from '../../../../newcomponents/CustomButton/CustomButton';
// import { useWindowSize } from "../../../services/helper";
import SearchFileCodeFilters from './SearchUpdateFileCodeFilter';
import UpdateFileCodeList from './UpdateFileCodeList';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const UpdateFileCodeModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  const [isSearchingFileCode, setIsSearchingFileCode] = useState(false);
  const [isAssigningFileCode, setIsAssigningFileCode] = useState(false);
//   const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
//   const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [dataItems, setDataItems] = useState([]);
  const [fileCode, setFileCode] = useState(null);
  // const [width, height] = useWindowSize();

  return (
    <Modal
      {...props}
      className={[styles.updateObjectModalBody, 'glbUpdateFileCodeModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      centered
      // fullscreen={true}
      onHide={() => {
        props.onHide();
      }}
      data-testid="on-hide"
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <b className={styles.headingText}>Update Object Hold</b>
            </div>
            <div className={styles.modalHeaderIcon}>
              <img className={styles.resizeIcon} src={ResizeIcon} alt="resize icon" data-testid="resize-icon" />
              <img
                src={crossIcon}
                alt="cross icon"
                onClick={() => {
                  props.setShowUpdateFileCode(false);
                  setisRequiredError(false);
                }}
                data-testid="cross-icon"
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.searchPageModalBody}>
          <div>
            {/* {objFileCodePopupOpen && */}
            {/* <Window
                                style={{ paddingLeft: "10px", paddingRight: "10px" }}
                                minimizeButton={() => null}
                                maximizeButton={() => null}
                                restoreButton={() => null} modal={true} draggable={false} left={windowWidth ? windowWidth * .15 : width * .2} initialTop={windowHeight ? windowHeight * .01 : height * .05} onClose={() => toggleUpdateObjFileCodePopup(false)} height={windowHeight ? windowHeight * .9 : height * .9} width={windowWidth ? windowWidth * .6 : width * .60} >
                                {loadingFileCodes ? (
                                    <div className={styles.center}>
                                        <CircularProgress />

                                    </div>
                                ) : */}
            <div>
              {/* <h2 className={styles.updateObjFileCodeTitle}>Update Object File Code</h2> */}
              <div className={styles.updateObjFileContent}>
                <div className={styles.searchFileCode}>
                  <div>
                    <div>
                      {' '}
                      <strong className={styles.fileCodeHeadingText} style={{ marginTop: '0px' }}>
                        Search File Codes
                      </strong>
                    </div>
                    <SearchFileCodeFilters setFileCode={setFileCode} setIsSearchingFileCode={setIsSearchingFileCode} setDataItems={setDataItems}></SearchFileCodeFilters>
                  </div>
                </div>
                <div className={styles.fileCodeViewerSection}>
                  <div>
                    {' '}
                    <strong className={styles.fileCodeHeadingText}>Select a File Code</strong>{' '}
                  </div>

                  <div className={styles.fileCodeViewer}>
                    {/* <div style={{ minWidth: "100%", marginBottom: "1em" }} className="fsa-divider"></div> */}
                    <div style={{ minWidth: '100%', overflowX: 'hidden', minHeight: '100px' }}>
                      {isAssigningFileCode || isSearchingFileCode ? (
                        <div className="fsa-progress fsa-progress--indeterminate" aria-live="polite">
                          <div className="fsa-progress__details">
                            <div className="fsa-progress__label">
                              {isAssigningFileCode ? 'Assigning File Code' + fileCode + ' to one or more Documents' : 'Retrieving File Codes'}
                            </div>
                          </div>
                          <div className="fsa-progress__bar" aria-hidden="true">
                            <div className="fsa-progress__primary"></div>
                            <div className="fsa-progress__secondary"></div>
                          </div>
                        </div>
                      ) : (
                        <UpdateFileCodeList setFileCode={setFileCode} dataItems={dataItems}></UpdateFileCodeList>
                      )}
                    </div>
                  </div>
                  <div className={styles.totalResultText}>
                    <span className={styles.resultText}>Showing top 25 results</span>
                  </div>
                </div>
              </div>

              {/* <div style={{ marginTop: "1em", marginRight: "3em" }}>
                                <div style={{ marginLeft: "85%" }}>Showing top 25 results</div>

                                <div style={{ display: "flex", alignItems: "flex-end", marginTop: "5em", marginLeft: "25em" }} >
                                    <div className={styles.alertWarning} >
                                                    <FontAwesomeIcon style={{ fontSize: "17px", marginTop: "10px" }} color="#494440" icon={faExclamationTriangle} />
                                                    <font color={"#2F2C2A"}> <b>This action will override one or more objects' current File Code.</b></font>
                                                </div>
                                    <button disabled={fileCode === null} style={{ marginRight: "0.25em" }}
                                                //  onClick={() => assignFileCodeToDocs()} 
                                                 className="fsa-btn"><span>Update</span></button>
                                                <button 
                                                // onClick={() => toggleUpdateObjFileCodePopup(false)} 
                                                className="fsa-btn fsa-btn--secondary"><span>Cancel</span></button>
                                </div>
                            </div> */}
            </div>
            {/* } */}

            {/* </Window> */}
            {/* } */}
            {/* <ErrorGrowl
                            isErrorGrowlHidden={isErrorGrowlHidden}
                            setIsErrorGrowlHidden={setIsErrorGrowlHidden}
                            message={"File code could not be assigned to one or more documents."}
                        />
                        <SuccessProcessGrowl
                            isSuccessGrowlHidden={isSuccessGrowlHidden}
                            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
                            message={"Successfully assigned file code to one or more documents."}
                        /> */}
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.searchPageFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? <CustomButton title="Save" className={styles.saveRecordBtn} /> : <CustomButton title="Update" className={styles.saveRecordBtn} />}

            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setShowUpdateFileCode(false);
                setisRequiredError(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default UpdateFileCodeModal;
