import React from 'react';
import crossIcon from '../../fsa-style/img/crossIcon.svg';
import { Modal } from 'react-bootstrap';
import styles from './deletepopup.module.scss';
import CustomButton from '../CustomButton/CustomButton';
import PropTypes from 'prop-types';

/**
 * DeleteModal component for displaying a confirmation modal for deleting records.
 *
 * This component provides a modal interface for confirming the deletion of records. It includes options to cancel or proceed with the deletion.
 *
 * @component
 */

export const DeleteModal = (props) => {
  return (
    <Modal
      {...props.props}
      show={props.show}
      size="md"
      data-testid="modal-container"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles.deleteContainer}
      onHide={props.onHide}
      style={{ zIndex: 999999 }}
    >
      <div className={styles.delete_modal_popup}>
        <Modal.Header>
          <div className={styles.modal_header_section}>
            <div>
              <h2 className={styles.modal_header_panel_text}>Delete {props.popupTitle}</h2>
            </div>
            <div className={styles.modal_header_panel_icon} onClick={props.onHide}>
              <img data-testid="modalClose" src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.flex_container_custom}>
            <div className={styles.my_div}>
              <img src={crossIcon} className={styles.delete_modal_delete_icon} alt="cross icon" />
            </div>
            <div className={styles.delete_modal_text_wrapper}>
              <span className={styles.delete_modal_heading}>Are you sure?</span>
              <div data-testid="deletionMessage" className={styles.delete_modal_text}>
                Do you really want to delete {props.popupTitle !== '' && `this ${props.popupTitle.toLowerCase()}`}? <br />
                click delete button to confirm
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className={styles.modal_footer_section}>
            <CustomButton
              title="Delete"
              className={styles.modal_footer_delete_button}
              onClick={() => {
                props.DeleteDataFunc(props.selectedDeleteRowData);
              }}
            />
            <CustomButton data-testid="modalCancelBtn" title="Cancel" className={styles.modal_footer_cancel_button} onClick={props.onHide} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  /**
   * Props to be passed to the underlying Modal component.
   */
  props: PropTypes.object.isRequired,

  /**
   * Flag to control the visibility of the modal.
   */
  show: PropTypes.bool.isRequired,

  /**
   * Function to be called when the modal is hidden.
   */
  onHide: PropTypes.func.isRequired,

  /**
   * Function to be called when the delete button is clicked.
   */
  DeleteDataFunc: PropTypes.func.isRequired,

  /**
   * Data related to the selected row to be deleted.
   */
  selectedDeleteRowData: PropTypes.any.isRequired,
};
