import React, { useEffect, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';

// import './redactionHistory.scss';
import styles from './RedactionHistory.module.scss';
import '../../../GlobalStyle.scss';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';

// import redactionHistoryTable from './redactionHistoryTable';
import RedactionHistoryData from './RedactionHistoryData.json';
import AsyncSelect from 'react-select/async';

import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { searchRedactionHistory } from './RedactionHistoryService.js';

// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Redaction History', link: '' },
];

const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const RedactionHistoryTable = lazy(() => import('./RedactionHistoryTable.js'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));

export default function RedactionHistory() {
  const glbContextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [isLoading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [recordModalShow, setRecordModalShow] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [redactionHistoryDetails, setRedactionHistoryData] = useState(RedactionHistoryData);

  const [inputDisabled, setInputDisabled] = useState(false);
  const userContextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

  const [formData, setFormData] = useState({
    activeInd: '',
    contextId: glbContextId,
    buowId: '',
    objectId: '',
    objectName: '',
    objectElementId: '',
    objectElementName: '',
    objectElementType: '',
    ruleSequence: '',
    dttmStmp: '',
    actorType: '',
    actorId: '',
    redactionRule: '',
    oldOrNew: '',
    redact: '',
    redactionType: '',
    conditionalRuleId: '',
    defaultRedactionReason: '',
    redactionFormalReference: '',
    visualStartX: '',
    visualStartY: '',
    visualEndX: '',
    visualEndY: '',
    annotation: '',
    ruleLogId: '',
  });

  async function fetchredactionHistoryData() {
    let payload = {
      contextId: userContextId,
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchRedactionHistory(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchredactionHistoryData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      contextId: userContextId,
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchRedactionHistory(payload).then((response) => {
      setData(response.data);

      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  const editFunc = async (data) => {
    setFormData({
      activeInd: data.activeInd,
      contextId: data.contextId,
      buowId: data.buowId,
      objectId: data.objectId,
      objectName: data.objectName,
      objectElementId: data.objectElementId,
      objectElementName: data.objectElementName,
      objectElementType: data.objectElementType,
      ruleSequence: data.ruleSequence,
      dttmStmp: data.dttmStmp,
      actorType: data.actorType,
      actorId: data.actorId,
      redactionRule: data.redactionRule,
      oldOrNew: data.oldOrNew,
      redact: data.redact,
      redactionType: data.redactionType,
      conditionalRuleId: data.conditionalRuleId,
      defaultRedactionReason: data.defaultRedactionReason,
      redactionFormalReference: data.redactionFormalReference,
      visualStartX: data.visualStartX,
      visualStartY: data.visualStartY,
      visualEndX: data.visualEndX,
      visualEndY: data.visualEndY,
      annotation: data.annotation,
      ruleLogId: data.ruleLogId,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      contextId: userContextId,
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchRedactionHistory(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);

      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRedactionHistoryData = JSON.parse(JSON.stringify(RedactionHistoryData));
    newRedactionHistoryData.forEach((itm) => {
      itm.sortType = '';
    });
    setRedactionHistoryData(newRedactionHistoryData);
    await fetchNewRedactionHistoryData();
  };

  // Code needs to change
  const fetchNewRedactionHistoryData = async () => {
    let payload = {
      contextId: userContextId,
      text: '',
      column: '',
      order: '',
    };
    await searchRedactionHistory(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      contextId: userContextId,
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchRedactionHistory(payload).then((response) => {
      setData(response.data);

      setLoading(false);
    });
  };

  return (
    <div className={styles.redactionHistoryPage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.redactionHistoryPageContent}>
        <h1 className={styles.pageTitle}>Redaction History</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.tableRedactionHistory, 'glbTableRedactionHistory'].join(' ')}>
          <RedactionHistoryTable
            data={data}
            page={page}
            editFunc={editFunc}
            pageSize={pageSize}
            fields={redactionHistoryDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <AddEditRedactionHistoryModal
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          // setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          // setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          // fetchredactionHistoryData={() => onSearch(searchKeyword)}
          // setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
          glbContextId={glbContextId}
        />
      </div>
    </div>
  );
}

const AddEditRedactionHistoryModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(true);
  const [objectElementIds, setObjectElementIds] = useState([]);
  const [defaultRedactionReasonList, setDefaultRedactionReasonList] = useState([]);
  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const handleCancel = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };
  const handleModalCrossIconBtn = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };

  return (
    <Modal
      {...props}
      className={[styles.redactionHistoryModal, 'glbRedactionHistoryModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{'View Redaction History'}</h2>
            </div>
            <div className={styles.modalHeaderIcon} onClick={() => handleModalCrossIconBtn()}>
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className={styles.redactionHistoryModalBody}>
          <div className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Context ID *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  isDisabled={true}
                  // loadOptions={loadObjectsList}
                  value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                  // onChange={onChangeObjectId}
                  isSearchable={false}
                  defaultOptions
                  className={props.isNew ? '' : styles.disabledInput}
                />
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>BUOW ID</label>
                <input disabled name="buowId" required value={props.formData.buowId} onChange={{}} />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Object Name</label>
                <input disabled name="objectName" required value={props.formData.objectName} onChange={{}} />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Element Name</label>
                  <input disabled name="objectElementName" required value={props.formData.objectElementName} onChange={{}} />
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Object Element Type</label>
                <input disabled name="objectElementType" required value={props.formData.objectElementType} onChange={{}} />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Rule Sequence</label>
                  <input disabled name="ruleSequence" required value={props.formData.ruleSequence} onChange={{}} />
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>DTTM Stmp</label>
                <input disabled name="dttmStmp" required value={props.formData.dttmStmp} onChange={{}} />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Actor Type</label>
                  <input disabled name="actorType" required value={props.formData.actorType} onChange={{}} />
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Actor ID</label>
                <input disabled name="actorId" required value={props.formData.actorId} onChange={{}} />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Redaction Rule</label>
                  <input disabled name="redactionRule" required value={props.formData.redactionRule} onChange={{}} />
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Old/New</label>
                <input disabled name="oldOrNew" required value={props.formData.oldOrNew} onChange={{}} />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Redact</label>
                  <input disabled name="redact" required value={props.formData.redact} onChange={{}} />
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Redaction Type</label>
                <input disabled name="redactionType" required value={props.formData.redactionType} onChange={{}} />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Conditional Rule ID</label>
                  <input disabled name="conditionalRuleId" required value={props.formData.conditionalRuleId} onChange={{}} />
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Default Redaction Reason</label>
                <input disabled name="defaultRedactionReason" required value={props.formData.defaultRedactionReason} onChange={{}} />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Redaction Formal Reference</label>
                  <input disabled name="redactionFormalReference" required value={props.formData.redactionFormalReference} onChange={{}} />
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Visual Start X%</label>
                <input disabled name="visualStartX" required value={props.formData.visualStartX} onChange={{}} />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Visual Start Y%</label>
                  <input disabled name="visualStartY" required value={props.formData.visualStartY} onChange={{}} />
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Visual End X%</label>
                <input disabled name="visualEndX" required value={props.formData.visualEndX} onChange={{}} />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Visual End Y%</label>
                  <input disabled name="visualEndY" required value={props.formData.visualEndY} onChange={{}} />
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Annotation</label>
                <input disabled name="annotation" required value={props.formData.annotation} onChange={{}} />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Rule Log ID</label>
                  <input disabled name="ruleLogId" required value={props.formData.ruleLogId} onChange={{}} />
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.radioInputOuterDiv, 'mt-3'].join(' ')} data-testid="radio-function">
                <div>Active Ind: </div>
                <div style={{ display: 'flex' }}>
                  <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} disabled />
                  True
                  <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} disabled /> False
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
